import { AxiosError } from "axios";
import { commonApiErrorHandler } from "pages/zen/services/apis/handlers/errors";
import ApiService from "services/api";
import { LegalEntity } from "types/legal-entities";
import {
  PartnerFlowEventStatus,
  RequestDataCreateLeadFromLegalEntity,
  RequestGetLeadByLegalEntity,
} from "types/partnerships";
import { ERROR_MESSAGES } from "utils/constants";
import pollingRequest from "utils/pollingRequest";

export const createLeadFromLegalEntity = async (
  legalEntityId: LegalEntity["id"],
  data: RequestDataCreateLeadFromLegalEntity
) => {
  try {
    const response = await ApiService.partnerships.createLeadFromLegalEntity(
      legalEntityId,
      data
    );

    if (response?.status !== 204) {
      throw new AxiosError(
        "[PartnershipService] Failed to create Lead from LegalEntity",
        String(response.status),
        response.config,
        response.request,
        response
      );
    }
  } catch (error: any) {
    return commonApiErrorHandler(error);
  }
};

export const getLeadByLegalEntity = async (
  pathVariables: RequestGetLeadByLegalEntity["request"]["pathVariables"]
) => {
  try {
    const result = await pollingRequest<
      RequestGetLeadByLegalEntity["response"]["data"]
    >({
      request: () =>
        ApiService.partnerships.getLeadByLegalEntity(pathVariables),
      successCondition: (response) =>
        response?.data?.status ===
        PartnerFlowEventStatus.SuccessSubscriptionCreation,
      failureCondition: (response) =>
        !!response?.data?.status?.includes("error"),
      delay: 1000,
      retries: 60, // 60 * 1000ms = 1 minute
    });

    if (result?.data?.status?.includes("error")) {
      throw new Error(result?.data?.detail || ERROR_MESSAGES.DEFAULT, {
        cause: result.data.status,
      });
    }

    return result;
  } catch (error: any) {
    throw error;
  }
};
