import { Elements } from "@stripe/react-stripe-js";
import {
  Button,
  Column,
  Hint,
  HintType,
  Label,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { LockFlat } from "@yolaw/ui-kit-icons";
import Modal, { CustomHTMLDialogElement } from "components/modal/Modal";
import PaymentFormContext from "components/payment-form/PaymentFormContext";
import PaymentMethodIcons from "components/payment-form/PaymentMethodIcons";
import PaymentMethodSelector from "components/payment-form/PaymentMethodSelector";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useSegment } from "hooks";
import StripeProvider from "providers/stripe";
import { useEffect, useReducer, useRef } from "react";
import styled, { css } from "styled-components";
import { LSPaymentMethod } from "types";
import SetupConfirmButton from "./SetupConfirmButton";
import SetupPaymentMethodContext from "./SetupPaymentMethodContext";

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    .modal-container-inner {
      @media (min-width: ${theme.breakpoints.l}px) {
        max-width: ${theme.breakpoints.l}px;
      }
    }
  `}
`;

const StyledTitle = styled(Title)`
  text-align: center;
`;

const PayWithContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledLabel = styled(Label)`
  text-align: center;
  svg {
    display: initial;
  }
`;

const PadlockIcon = styled(LockFlat)`
  vertical-align: top;
`;

const Container = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;

    .actions_container {
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing.xs}px;
    }

    @container (max-width: ${theme.breakpoints.s}px) {
      .actions_container {
        flex-direction: column-reverse;
      }
    }
    @container (min-width: ${theme.breakpoints.s}px) {
      margin: 0 ${theme.spacing.xxxl}px ${theme.spacing.s}px;
    }
  `}
`;

type Props = {
  setupFor: LSPaymentMethod.CardProcessorType;
};
const SetupNewPaymentMethodModal = ({ setupFor }: Props) => {
  const app = useApp();
  const isMobile = useIsMobile();
  const segment = useSegment();

  const modalRef = useRef<CustomHTMLDialogElement | null>(null);

  const [setupPaymentMethodState, setupPaymentMethodDispatch] = useReducer(
    SetupPaymentMethodContext.reducer,
    { ...SetupPaymentMethodContext.initialState, setupFor }
  );
  const { isSubmitting } = setupPaymentMethodState;

  const [paymentFormState, paymentFormDispatch] = useReducer(
    PaymentFormContext.reducer,
    PaymentFormContext.initialState
  );

  const { paymentError } = paymentFormState;

  useEffect(() => {
    setupPaymentMethodDispatch({
      type: SetupPaymentMethodContext.Action.SetModalRef,
      payload: modalRef,
    });
  }, [modalRef]);

  useEffect(() => {
    segment.track("payment method update form: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    app.closeModal();
  };

  return (
    <StyledModal
      isOpen={app.openingModal?.name === ModalName.PaymentMethodSetup}
      onClose={closeModal}
      ref={modalRef}
    >
      <Elements
        stripe={StripeProvider.stripePromise}
        options={{ mode: "setup", currency: "eur", amount: 0 }}
      >
        <SetupPaymentMethodContext.Context.Provider
          value={{
            state: setupPaymentMethodState,
            dispatch: setupPaymentMethodDispatch,
          }}
        >
          <PaymentFormContext.Context.Provider
            value={{ state: paymentFormState, dispatch: paymentFormDispatch }}
          >
            <Container>
              <StyledTitle type={isMobile ? "H1" : "H3"}>
                Ajouter un nouveau moyen de paiement
              </StyledTitle>

              <PayWithContainer>
                <PaymentMethodIcons />
              </PayWithContainer>

              <StyledLabel fontWeightVariant="bold">
                <PadlockIcon /> SSL Secure Connection
              </StyledLabel>

              <PaymentMethodSelector />

              {paymentError && (
                <Hint type={HintType.Error}>
                  L'ajout de votre moyen de paiement a échoué.
                  <br />
                  <Text color="neutral.darker">{paymentError.message}</Text>
                </Hint>
              )}

              <div className="actions_container">
                <Button
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={closeModal}
                >
                  Annuler
                </Button>
                <SetupConfirmButton />
              </div>
            </Container>
          </PaymentFormContext.Context.Provider>
        </SetupPaymentMethodContext.Context.Provider>
      </Elements>
    </StyledModal>
  );
};

export default SetupNewPaymentMethodModal;
