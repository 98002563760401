export enum TTimelineElementStatus {
  Done = "done",
  Active = "active",
  Locked = "locked"
}

export interface TTimelineSection {
  title: string;
  status: TTimelineElementStatus;
  description: string;
  button?: JSX.Element;
}
