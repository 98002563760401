import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { useApp, useIsMobile, useSegment } from "hooks";
import workingOnItImgSrc from "pages/subscriptions-management/assets/undraw_dev_focus_re_6iwt 1.svg";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import Subscription from "types/subscriptions";
import { ChangePlanType } from "../ChangePlanContext";

const getContent = (downOrUp?: ChangePlanType) => {
  switch (downOrUp) {
    case ChangePlanType.Down:
      return (
        <>
          <Text>
            Votre nouveau plan sera actif à partir du prochain cycle de
            facturation.
          </Text>
          <Text>
            En attendant, vous pouvez continuer à profiter des fonctionnalités
            de votre plan actuel.
          </Text>
        </>
      );
    case ChangePlanType.Up:
    default:
      return <Text>Il sera effectif sous 4 heures ouvrées.</Text>;
  }
};

type Props = {
  currentSubscription: Subscription;
  newProduct: Subscription["product"];
  downOrUp: ChangePlanType;
  onConfirm: VoidFunction;
};
const SwitchTierSuccessModal = ({
  currentSubscription,
  newProduct,
  downOrUp,
  onConfirm,
}: Props) => {
  const app = useApp();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const segment = useSegment();

  useEffect(() => {
    segment.track("subscription product: updated", {
      "legalentity id": app.currentLegalEntityId,
      "sub family": currentSubscription?.family.slug,
      "update type": downOrUp === ChangePlanType.Up ? "upsell" : "downsell",
      "current product": currentSubscription?.product.slug,
      "new product": newProduct.slug,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalAndStay = () => {
    app.closeModal();
  };

  return (
    <Modal isOpen={true} onClose={closeModalAndStay} isLocked hideCloseButton>
      <Column style={{ rowGap: `${theme.spacing.s}px`, alignItems: "center" }}>
        <img
          src={workingOnItImgSrc}
          style={{ width: 162, height: 104 }}
          alt="working on it"
        />
        <span style={{ textAlign: isMobile ? "left" : "center" }}>
          <Title type={isMobile ? "H1" : "H3"}>
            Votre changement a bien été pris en compte
          </Title>
        </span>
        <Column
          style={{
            rowGap: 0,
            textAlign: isMobile ? "left" : "center",
            color: theme.colors.neutral.darker,
          }}
        >
          {getContent(downOrUp)}
        </Column>

        <Button onClick={onConfirm}>J'ai compris</Button>
      </Column>
    </Modal>
  );
};

export default SwitchTierSuccessModal;
