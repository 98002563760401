import { ModalName } from "contexts/app";
import { useApp, useSegment } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { SubscriptionViewPath } from "pages/subscriptions-management/router";
import { useContext, useEffect, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import { BuiltRoutePath } from "services/router";
import SubscriptionViewContext from "../SubscriptionViewContext";
import ChangePlanContext from "./ChangePlanContext";
import SwitchTierSuccessModal from "./components/SwitchTierSuccessModal";
import BenefitsComparisonPage from "./downsell/BenefitsComparisonPage";
import DownsellConfirmationModal from "./downsell/DownsellConfirmationModal";
import PlanSelectionPage from "./PlanSelectionPage";
import UpsellConfirmationModal from "./upsell/UpsellConfirmationModal";

const ChangePlanPage = () => {
  const app = useApp();
  const segment = useSegment();

  const { navigateTo } = useAppRoutes();
  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);
  const { currentSubscription } = subscriptionViewContext.state;

  const [changePlanState, changePlanDispatch] = useReducer(
    ChangePlanContext.reducer,
    ChangePlanContext.initialState
  );

  useEffect(() => {
    if (currentSubscription) {
      segment.track("subscription management page: displayed", {
        "legalentity id": app.currentLegalEntityId,
        "sub family": currentSubscription?.family.slug,
        "sub product": currentSubscription?.product.slug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscription?.id]);

  const { newPlan, downOrUp } = changePlanState;

  const closeModalAndNavigateToSubscriptionListing = () => {
    app.closeModal();
    navigateTo(BuiltRoutePath.SubscriptionsManagementPage, { replace: true });
  };

  return (
    <ChangePlanContext.Context.Provider
      value={{ state: changePlanState, dispatch: changePlanDispatch }}
    >
      <Routes>
        <Route index element={<PlanSelectionPage />} />
        <Route
          path={SubscriptionViewPath.BenefitsComparisonPage}
          element={<BenefitsComparisonPage />}
        />
      </Routes>

      {app.openingModal?.name === ModalName.SubsMgmntUpsellConfirmation &&
        currentSubscription &&
        newPlan && (
          <UpsellConfirmationModal
            currentSubscription={currentSubscription}
            newPlan={newPlan}
          />
        )}
      {app.openingModal?.name === ModalName.SubsMgmntDownsellConfirmation && (
        <DownsellConfirmationModal />
      )}
      {app.openingModal?.name === ModalName.SubsMgmntSwitchTierSuccess &&
        currentSubscription &&
        newPlan &&
        downOrUp && (
          <SwitchTierSuccessModal
            currentSubscription={currentSubscription}
            newProduct={newPlan.product}
            downOrUp={downOrUp}
            onConfirm={closeModalAndNavigateToSubscriptionListing}
          />
        )}
    </ChangePlanContext.Context.Provider>
  );
};

export default ChangePlanPage;
