export enum SubscriptionFamilySlug {
  LSBusiness = "ls-business",
}

/** Enum for LSCP product slugs */
export enum LSCPProductSlug {
  Basic = "compte-pro-basic",
  Essential = "compte-pro-essential",
  Premium = "compte-pro-premium",
  LegalstartOne = "legalstart-one",
}

export enum LSCPProductLine {
  CompteProLegalstart = "compte-pro-legalstart",
  LegalstartOne = "legalstart-one",
}

export enum LSCPProductFeature {
  GenerateInvoice = "GenerateInvoice",
  MyExpenses = "MyExpenses",
  MyLatestTransactions = "MyLatestTransactions",
  ProAccount = "ProAccount",
}

interface SubscriptionFamily {
  id: number;
  display_name?: string | null;
  name: string;
  slug: SubscriptionFamilySlug;
}

export interface SubscriptionProductFeatureItem {
  contained_in_product: boolean;
  description: string;
  label_downselling?: string;
  label_loosing?: string;
  count?: number;
  is_highlighted?: boolean;
}

interface SubscriptionProductExtra {
  display?: {
    main_display?: {
      icon: string;
      sub_title: string;
      display_name: string;
      list_description: string[];
      highlight_text?: string;
    };
    feature_display?: {
      name: string;
      list_description: SubscriptionProductFeatureItem[];
    }[];
    checkout_display?: any;
  };
  features: LSCPProductFeature[];
  available_upsells: SubscriptionProduct["slug"][];
  available_downsells: SubscriptionProduct["slug"][];
}

export interface SubscriptionProduct extends SubscriptionProductExtra {
  id: number;
  name: string;
  slug: string;
}

export interface SubscriptionTier {
  id: number;
  flat_price: number;
  price_per_unit: number;
  up_to: number | null;
  total_tax: number | null;
  total_price_it: number | null;
}

interface SubscriptionPlanShort {
  id: number;
  period_unit: string;
  period_interval: number;
  trialing_unit: string | null;
  trialing_amount: number | null;
  is_active: boolean;
}

export interface SubscriptionPlan
  extends Omit<SubscriptionPlanShort, "is_active"> {
  family: SubscriptionFamily;
  product: SubscriptionProduct;
  tiers: SubscriptionTier[];
  slug: string;
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Draft = "draft",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Paused = "paused",
  Trialing = "trialing",
  RenewalFailure = "renewal_failure",
  /** @deprecated Defined in BE but seems not in used */
  Unpaid = "unpaid",
}

/** Accessible LS Compte Pro subscription statuses decided by product team */
export const LSCPAccessibleStatuses = [
  SubscriptionStatus.Draft,
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Active,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.RenewalFailure,
];

export enum SubscriptionPlannedAction {
  CANCEL = "CANCEL",
  UPSELL = "UPSELL",
  DOWNSELL = "DOWNSELL",
  CYCLE_CHANGE = "CYCLE_CHANGE",
}

export enum SubscriptionCategory {
  CapitalDeposit = "capital_deposit",
  Standalone = "standalone",
}

export interface Subscription {
  family: SubscriptionFamily;
  id: number;
  legal_entity_id: number;
  product: SubscriptionProduct;
  plan: SubscriptionPlanShort;
  redirection_url: string | null;
  status: SubscriptionStatus;

  next_billing_amount: number | null;
  next_billing_date: string | null;

  planned_action: SubscriptionPlannedAction | null;
  /** When `planned_action == UPSELL | DOWNSELL` */
  next_tier_id: number | null;
  /** When `planned_action == CANCEL` */
  cancel_at_period_end: boolean;
  canceled_at: string | null;
  cancellation_planned_date: string | null;
  requested_cancellation_at: string | null;

  tier: SubscriptionTier;

  created_at: string;
  trial_start_date: string | null;
  trial_end_date: string | null;
  current_period_start_date: string | null;
  current_period_end_date: string | null;

  category?: SubscriptionCategory | null;
}

export interface ProratedAmountResponseData {
  prorata_amount: number;
}

export interface TerminateSubscriptionResponseData {
  message: string;
}

export default Subscription;
