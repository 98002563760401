import { Spinner, Text } from "@yolaw/ui-kit-components";
import { useLegalEntity } from "hooks";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { CapitalDepositStep, LegalEntityDetails } from "types/legal-entities";
import addDocumentSrc from "../../assets/add-document.svg";
import certificateSrc from "../../assets/certificate.svg";
import doneCheckingSrc from "../../assets/done-checking.svg";
import inProgressSrc from "../../assets/in-progress.svg";
import timeManagementSrc from "../../assets/time-management.svg";
import ShareholderCapitalDepositAccessCard from "./ShareholderCapitalDepositAccessCard";
import ShareholderCapitalDepositWaitingCard from "./ShareholderCapitalDepositWaitingCard";
import { captureException } from "@sentry/react";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ShareholderCapitalDepositCard: React.FC = () => {
  const currentLegalEntity = useLegalEntity(null, {
    needKDep: true,
    needOwner: true,
  });

  if (!currentLegalEntity.owner) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }

  const kDep = currentLegalEntity.myKDep;

  switch (kDep?.step) {
    case undefined:
    case CapitalDepositStep.START:
    case CapitalDepositStep.IDENTITY_VERIFICATION:
    case CapitalDepositStep.IDENTITY_VERIFICATION_PENDING:
    case CapitalDepositStep.IDENTITY_VERIFICATION_ERROR:
    case CapitalDepositStep.TRANSFER:
      return (
        <ShareholderCapitalDepositAccessCard
          kDep={kDep}
          legalEntityDetails={currentLegalEntity as LegalEntityDetails}
        />
      );

    case CapitalDepositStep.FUNDS_TRANSFERRED:
      return (
        <ShareholderCapitalDepositWaitingCard
          title="En attente de vos associés"
          imgSrc={timeManagementSrc}
        >
          <Text>La vérification de vos documents est terminée.</Text>
          <Text>
            Pour préparer le certificat de dépôt des fonds, vos associés doivent
            terminer le dépôt de leur capital.
          </Text>
        </ShareholderCapitalDepositWaitingCard>
      );

    case CapitalDepositStep.DOCUMENT_VERIFICATION:
      return (
        <ShareholderCapitalDepositWaitingCard
          title="Vérification de vos documents"
          imgSrc={doneCheckingSrc}
        >
          <Text>
            La vérification des documents est en cours par notre partenaire
            Swan. Cette étape peut prendre jusqu'à 12 heures ouvrées.
          </Text>
        </ShareholderCapitalDepositWaitingCard>
      );

    case CapitalDepositStep.DOCUMENT_VERIFICATION_FAILED:
      return (
        <ShareholderCapitalDepositWaitingCard
          title="Demande complémentaire"
          imgSrc={addDocumentSrc}
        >
          <Text>
            Notre partenaire Swan a une demande complémentaire concernant votre
            dossier. Votre formaliste vous a contacté par email.
          </Text>
        </ShareholderCapitalDepositWaitingCard>
      );

    case CapitalDepositStep.CERTIFICATE:
      return (
        <ShareholderCapitalDepositWaitingCard
          title="Préparation du certificat de dépôt des fonds"
          imgSrc={certificateSrc}
        >
          <Text>
            Le certificat de dépôt des fonds est en cours de préparation par le
            notaire. Il sera disponible sous 12 heures ouvrées.
          </Text>
        </ShareholderCapitalDepositWaitingCard>
      );

    case CapitalDepositStep.DONE:
      return (
        <ShareholderCapitalDepositWaitingCard
          title="Immatriculation en cours"
          imgSrc={inProgressSrc}
        >
          <Text>
            Vous serez notifié quand celle-ci sera finalisée et quand vous
            pourrez accéder au Compte Pro Legalstart.
          </Text>
        </ShareholderCapitalDepositWaitingCard>
      );

    default:
      captureException(
        new Error(`ShareholderCapitalDepositCard: invalid step ${kDep?.step}`)
      );
      return <Navigate to="/" />;
  }
};

export default ShareholderCapitalDepositCard;
