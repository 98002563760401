import { useQuery } from "@tanstack/react-query";
import { Column, Spinner, Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import {
  useApp,
  useIsMobile,
  useLegalEntity,
  useSegment,
  useUser,
} from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import { LSPaymentMethod } from "types";
import PaymentMethodItem from "./components/PaymentMethodItem";
import PaymentMethodSection from "./components/PaymentMethodSection";
import DetachDefaultPaymentMethodModal from "./components/SetupModal/DetachDefaultPaymentMethodModal";
import SetupNewPaymentMethodModal from "./components/SetupModal/SetupNewPaymentMethodModal";
import {
  CardWarning,
  findLegalEntityPaymentMethod,
  findUserPaymentMethod,
  getCardWarning,
} from "./utilities";

const LegalEntityPaymentMethod = () => {
  const app = useApp();
  const legalEntity = useLegalEntity();

  if (!legalEntity.paymentMethod) return null;

  enum LEModalOpenFrom {
    AddForLE = "add-for-legal-entity",
    ChangeForLE = "change-for-legal-entity",
    DetachForLE = "detach-for-legal-entity",
  }

  const handleAdd = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: LEModalOpenFrom.AddForLE,
      },
    });
  };

  const handleChange = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: LEModalOpenFrom.ChangeForLE,
      },
    });
  };

  const handleRemove = () => {
    app.openModal({
      name: ModalName.PaymentMethodDetach,
      openedBy: {
        context: "payment-methods-page",
        from: LEModalOpenFrom.DetachForLE,
      },
    });
  };

  const isLEModal = Object.values(LEModalOpenFrom).includes(
    app.openingModal?.openedBy?.from as LEModalOpenFrom
  );

  return (
    <>
      <PaymentMethodSection
        title={`Moyen de paiement de la société ${legalEntity.name}`}
        subTitle="Carte utilisée pour payer les abonnements rattachés à la société."
      >
        <PaymentMethodItem
          paymentMethod={legalEntity.paymentMethod}
          handleAdd={handleAdd}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      </PaymentMethodSection>

      {isLEModal && app.openingModal?.name === ModalName.PaymentMethodSetup && (
        <SetupNewPaymentMethodModal
          setupFor={LSPaymentMethod.CardProcessorType.Company}
        />
      )}
      {isLEModal &&
        app.openingModal?.name === ModalName.PaymentMethodDetach && (
          <DetachDefaultPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.Company}
          />
        )}
    </>
  );
};

const UserPaymentMethod = () => {
  const app = useApp();
  const user = useUser();

  if (!user.paymentMethod) return null;

  enum UserModalOpenFrom {
    AddForUser = "add-for-user",
    ChangeForUser = "change-for-user",
    DetachForUser = "detach-for-user",
  }

  const handleAdd = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: UserModalOpenFrom.AddForUser,
      },
    });
  };
  const handleChange = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: UserModalOpenFrom.ChangeForUser,
      },
    });
  };
  const handleRemove = () => {
    app.openModal({
      name: ModalName.PaymentMethodDetach,
      openedBy: {
        context: "payment-methods-page",
        from: UserModalOpenFrom.DetachForUser,
      },
    });
  };

  const isUserModal = Object.values(UserModalOpenFrom).includes(
    app.openingModal?.openedBy?.from as UserModalOpenFrom
  );

  return (
    <>
      <PaymentMethodSection
        title="Moyen de paiement rattaché au compte utilisateur"
        subTitle="Carte utilisée pour toutes les démarches rattachées à votre compte."
      >
        <PaymentMethodItem
          paymentMethod={user.paymentMethod}
          handleAdd={handleAdd}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      </PaymentMethodSection>

      {isUserModal &&
        app.openingModal?.name === ModalName.PaymentMethodSetup && (
          <SetupNewPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.User}
          />
        )}

      {isUserModal &&
        app.openingModal?.name === ModalName.PaymentMethodDetach && (
          <DetachDefaultPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.User}
          />
        )}
    </>
  );
};

const getPMStatusForTracking = (
  paymentMethod: LSPaymentMethod.PaymentMethodObject | undefined
) => {
  const pm = paymentMethod?.payment_method;
  if (!!pm?.sepa_debit) return "sepa";

  if (!!pm?.card) {
    const cardWarning = getCardWarning(pm.card.exp_month, pm.card.exp_year);
    if (cardWarning === CardWarning.Expired) return "expired";
    return "active";
  }

  return "missing";
};

const PaymentMethodsPage = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const segment = useSegment();
  const theme = useTheme();

  const getPaymentMethodsQuery = useQuery({
    queryKey: ["paymentMethods", app.currentLegalEntityId],
    queryFn: app.getPaymentMethods,
  });

  const { isPending, data } = getPaymentMethodsQuery;

  useEffect(() => {
    if (data) {
      app.setPaymentMethods(data);

      const lePM = findLegalEntityPaymentMethod(data);
      const userPM = findUserPaymentMethod(data);
      segment.track("payment method management: displayed", {
        le_payment_method_status: getPMStatusForTracking(lePM),
        user_payment_method_status: getPMStatusForTracking(userPM),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageContainer>
        <Column style={{ rowGap: theme.spacing.l }}>
          <Title type={isMobile ? "H1" : "H2"}>
            Gérer mon moyen de paiement
          </Title>

          {isPending ? (
            <Spinner />
          ) : (
            <>
              {/* LE's PM */}
              <LegalEntityPaymentMethod />
              {/* User's PM */}
              <UserPaymentMethod />
            </>
          )}
        </Column>
      </PageContainer>
    </>
  );
};

export default PaymentMethodsPage;
