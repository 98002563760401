import {
  Button,
  Column,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useIsMobile, useSegment } from "hooks";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { BuiltRoutePath } from "services/router";
import { EmailUtils } from "utils";
import {
  FormFooterActionContainer,
  FormInnerContainer,
} from "../components/misc-components";

type FormInputs = {
  email: string;
};
export const PasswordResetCollectEmailPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const segment = useSegment();

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });
  const { control, formState, handleSubmit } = formMethods;
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: FormInputs) => {
    if (!data.email) return;

    navigate(BuiltRoutePath.PasswordResetRequestPage + window.location.search, {
      replace: true,
      state: { email: data.email },
    });
  };

  useEffect(() => {
    segment.track("password reset email form: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInnerContainer>
        <Column>
          <Title
            text="Renseignez votre email"
            type={isMobile ? "H1" : "H3"}
            color="secondary.main"
          />
          <Text>Entrez votre email pour réinitialiser votre mot de passe</Text>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Le champ ci-dessus est requis",
              pattern: {
                value: EmailUtils.EMAIL_REGEX,
                message:
                  "Veuillez respecter le format email __________@_____.__",
              },
            }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                placeholder="Entrez votre email"
                autoFocus
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        <FormFooterActionContainer>
          <Link to={BuiltRoutePath.LoginPage + window.location.search} replace>
            Annuler
          </Link>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Continuer
          </Button>
        </FormFooterActionContainer>
      </FormInnerContainer>
    </form>
  );
};
