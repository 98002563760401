import { AppContext } from "contexts";
import { findUserPaymentMethod } from "pages/payment-methods/utilities";
import { useContext } from "react";
import { AuthService, LegacySubscriptionService } from "services";
import { redirectToLoginPage } from "services/auth/redirection";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import useSegment from "./useSegment";

export const useUser = () => {
  const segment = useSegment();
  const { state: appState } = useContext(AppContext.Context);
  const { user, legacySubscriptions, paymentMethods } = appState;

  const paymentMethod = findUserPaymentMethod(paymentMethods);

  /** Find in all subs list, no filter */
  const findLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ) =>
    LegacySubscriptionService.findLegacySubscription(
      legacySubscriptions,
      subSlugOrPrefix
    );

  /** user has subscribed a legacy subscription and the subscription is still active */
  const hasSubscribedLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ): boolean => !!findLegacySubscription(subSlugOrPrefix);

  const findAJActiveSubscription = () =>
    findLegacySubscription(LegacySubscriptionSlug.AssistanceJuridique) ||
    findLegacySubscription(LegacySubscriptionSlug.WebConformite) ||
    findLegacySubscription(LegacySubscriptionSlug.WebAssistanceJuridique) ||
    findLegacySubscription(LegacySubscriptionSlug.WebAe);

  const logout = () => {
    segment.track("user: logged out", {
      context: "userspace",
      button: "userspace",
    });
    AuthService.clearAuthInfo();

    redirectToLoginPage();
  };

  return {
    ...user,
    paymentMethod,

    // Legacy subscription finder methods
    findLegacySubscription,
    findAJActiveSubscription,

    // Subscription checker methods
    hasSubscribedLegacySubscription,
    hasSubscribedAJ: !!findAJActiveSubscription(),

    logout,
  };
};

export default useUser;
