import styled from "styled-components";

import TimelineSection from "./TimelineSection";
import { TTimelineSection } from "./timeline.types";

const Container = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.s}px;
`;

type Props = {
  sections: TTimelineSection[];
};

const Timeline = ({ sections }: Props) => {
  return (
    <Container>
      {sections.map((section, index) => (
        <TimelineSection key={section.title} section={section} index={index} />
      ))}
    </Container>
  );
};

export default Timeline;
