import { Button, Column, Tag, Text, Title } from "@yolaw/ui-kit-components";
import { useLegalEntity, useSegment } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import useLSCPSubscription from "pages/ls-compte-pro/hooks/useLSCPSubscription";
import { BuiltRoutePath, LSCPRoutePath } from "services/router";
import styled, { css } from "styled-components";
import { SubscriptionStatus } from "types/subscriptions";
import {
  CompteProCompanyStatus,
  CompteProTaskSlug,
} from "../../types/compte-pro";
import lscpPreview from "./assets/lscp-preview.png";
import lscpPreview2x from "./assets/lscp-preview@2x.png";
import lscpPreview3x from "./assets/lscp-preview@3x.png";
import CompteProTask from "./components/CompteProTask";
import HomePageSection from "./components/HomePageSection";
import { getCompteProCompanyStatus } from "./compte-pro-utils";
import { CapitalDepositStep } from "types/legal-entities";
import { ShareholderCapitalDepositCard } from "./components/ShareholderCapitalDepositCard";

const Container = styled<React.ElementType>(Column)`
  row-gap: inherit;
`;

const LSCPBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      padding: 0 ${theme.spacing.xs}px;

      &.column-gap-l {
        column-gap: ${theme.spacing.l}px;
      }
      &.column-gap-xs {
        column-gap: ${theme.spacing.xs}px;
      }
    }
  `}

  .img-lscp-preview {
    flex-shrink: 0;
    width: 266px;
    height: 122px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: image-set(
      url(${lscpPreview}) 1x,
      url(${lscpPreview2x}) 2x,
      url(${lscpPreview3x}) 3x
    );
  }

  .lscp-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const LegalEntityNameContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xs}px;
    align-items: flex-start;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      ${StyledTag} {
        align-self: center;
      }
    }
  `}
`;

const StyledTag = styled(Tag).attrs({ size: "small" })``;

const ActionsContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    flex-shrink: 0;
    align-self: stretch;

    @container (min-width: ${theme.breakpoints.m}px) {
      align-self: center;
    }
  `}
`;

type Props = {
  hasCompanyRegistered: boolean;
};
const CompteProSection = ({ hasCompanyRegistered }: Props) => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();
  const { navigateTo } = useAppRoutes();

  const compteProCompanyStatus = getCompteProCompanyStatus(currentLegalEntity);
  const lscpSubscription = useLSCPSubscription();

  if (!lscpSubscription) {
    throw new Error("LSCP subscription not found!");
  }

  const ssoURL = lscpSubscription.buildSSORedirectionURL(
    LSCPRoutePath.ProAccount
  );
  const isLEActive = compteProCompanyStatus === CompteProCompanyStatus.Active;
  const isShareholder = !currentLegalEntity.isLegalEntityOwner;
  const myKDep = currentLegalEntity.myKDep;

  const hasPaymentFailure = [
    SubscriptionStatus.PastDue,
    SubscriptionStatus.RenewalFailure,
  ].includes(lscpSubscription.status);

  const accessLSCP = () => {
    segment.track("ls compte pro cta: clicked", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: lscpSubscription.status,
    });

    window.open(ssoURL!);
  };

  const gotoPaymentMethodsPage = () => {
    navigateTo(BuiltRoutePath.PaymentMethodsForLEPage);
  };

  return (
    <Container>
      <LegalEntityNameContainer>
        <Title type={"H2"}>{currentLegalEntity.name}</Title>
        {hasCompanyRegistered ? (
          <StyledTag color="green">Société immatriculée</StyledTag>
        ) : (
          <StyledTag color="blue">Immatriculation en cours</StyledTag>
        )}
      </LegalEntityNameContainer>

      {isShareholder &&
      myKDep?.step !== CapitalDepositStep.ACCOUNT_ACTIVATED ? (
        <ShareholderCapitalDepositCard />
      ) : (
        <HomePageSection className="section--compte_pro">
          <Title type="H4">Mon Compte Pro tout-en-un</Title>

          <LSCPBlock className={isLEActive ? "column-gap-xs" : "column-gap-l"}>
            <span className="img-lscp-preview" />
            <div className="lscp-info">
              {isLEActive && (
                <>
                  {hasPaymentFailure ? (
                    <>
                      {lscpSubscription.status ===
                        SubscriptionStatus.PastDue && (
                        <>
                          <Text fontWeightVariant="bold" color="error.dark">
                            Votre paiement a échoué
                          </Text>
                          <Text>
                            Votre compte pro ne sera plus accessible en cas de
                            nouvel échec.
                          </Text>
                        </>
                      )}
                      {lscpSubscription.status ===
                        SubscriptionStatus.RenewalFailure && (
                        <>
                          <Text fontWeightVariant="bold" color="error.dark">
                            Votre paiement a échoué
                          </Text>
                          <Text>
                            Nous allons vous recontacter pour régulariser votre
                            situation.
                          </Text>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Text fontWeightVariant="bold">
                        Votre {lscpSubscription.familyDisplayName} est
                        disponible&nbsp;!
                      </Text>
                      <Text>
                        Payez, virez, encaissez : la solution tout-en-un la plus
                        efficace pour gérer vos finances.
                      </Text>
                    </>
                  )}
                </>
              )}
              {compteProCompanyStatus === CompteProCompanyStatus.Inactive && (
                <>
                  <Text fontWeightVariant="bold">
                    Votre {lscpSubscription.familyDisplayName} est bientôt
                    prêt&nbsp;!
                  </Text>
                  <Text>
                    Nous procéderons à sa création dès que votre société sera
                    immatriculée.
                  </Text>
                </>
              )}
              {compteProCompanyStatus === CompteProCompanyStatus.Waiting && (
                <>
                  <Text fontWeightVariant="bold">
                    Votre {lscpSubscription.familyDisplayName} est en cours de
                    création
                  </Text>
                  <Text>
                    Vous pourrez y accéder dès qu'il sera actif. Vous serez
                    notifié par email.
                  </Text>
                </>
              )}
            </div>
            {isLEActive && (
              <ActionsContainer>
                {hasPaymentFailure && (
                  <Button size="small" onClick={gotoPaymentMethodsPage}>
                    Mettre à jour ma carte
                  </Button>
                )}

                <Button
                  size="small"
                  variant={hasPaymentFailure ? "tertiary" : "primary"}
                  onClick={accessLSCP}
                  disabled={!ssoURL}
                >
                  Accéder à mon compte
                </Button>
              </ActionsContainer>
            )}
          </LSCPBlock>

          {/* Compte Pro Tasks */}
          <Column>
            <CompteProTask slug={CompteProTaskSlug.ActivateCard} />
            <CompteProTask slug={CompteProTaskSlug.SettingInvoices} />
          </Column>
        </HomePageSection>
      )}
    </Container>
  );
};

export default CompteProSection;
