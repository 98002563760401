import { Column, Text, Title } from "@yolaw/ui-kit-components";
import { UniversalInprogress } from "@yolaw/ui-kit-icons";
import { ModalName } from "contexts/app";
import {
  LegalEntityHookReturn,
  useApp,
  useIsMobile,
  useLegalEntity,
  useSegment,
} from "hooks";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import SubscriptionPlanList from "pages/components/subscriptions/SubscriptionPlanList";
import { useCallback, useEffect, useState } from "react";
import { SubscriptionsService } from "services";
import styled, { css } from "styled-components";
import {
  LSCPProductLine,
  SubscriptionFamilySlug,
  SubscriptionPlan,
} from "types/subscriptions";
import LSCPStandalonePaymentModal from "./PaymentModal/LSCPStandalonePaymentModal";

const StyledPageContainer = styled(PageContainer)`
  max-width: 1080px;
`;

const InfoBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-areas:
      "icon title"
      "description description";
    gap: ${theme.spacing.xs}px;

    background-color: white;
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};

    padding: ${theme.spacing.s}px;

    .icon {
      grid-area: icon;
    }
    .title {
      grid-area: title;
    }
    .description {
      grid-area: description;
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      grid-template-areas:
        "icon title"
        "icon description";
      grid-template-columns: max-content 1fr;
      row-gap: unset;
      align-items: center;
    }
  `}
`;

const PageHeader = styled<React.ElementType>(Column).attrs({ as: "header" })`
  ${({ theme }) => css`
    &.row-gap-m {
      row-gap: ${theme.spacing.m}px;
    }
  `}
`;

const getDynamicContent = (legalEntity: LegalEntityHookReturn) => {
  const productLine = legalEntity.eligibleLSCPProductLine;
  const productLineDisplayName =
    SubscriptionsService.getProductLineDisplayName(productLine);

  switch (productLine) {
    case LSCPProductLine.LegalstartOne:
      return {
        title: `Gérez votre activité avec ${productLineDisplayName}`,
        description:
          "Simplifiez la gestion de votre entreprise grâce à notre solution tout-en-un : facturation, comptabilité et compte pro.",
        isAllowedToSubscribe: true,
      };
    case LSCPProductLine.CompteProLegalstart:
    default:
      return {
        title: `Ouvrir un ${productLineDisplayName}`,
        description:
          "Débloquez la solution tout-en-un la plus efficace pour gérer vos finances : Cartes Mastercard, virements illimités et gratuits",
        isAllowedToSubscribe: !!legalEntity.siren,
      };
  }
};

const PlanSelectionPage = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const currentLegalEntity = useLegalEntity();
  const segment = useSegment();

  const [monthlyPlansToSell, setMonthlyPlansToSell] = useState<
    SubscriptionPlan[] | null
  >(null);

  const _getData = useCallback(async () => {
    try {
      const plans = await SubscriptionsService.getSubscriptionPlansForLE(
        SubscriptionFamilySlug.LSBusiness,
        currentLegalEntity.id
      );

      if (plans) {
        // Save in context
        setMonthlyPlansToSell(
          SubscriptionsService.filterMonthlyPlansToSell(plans)
        );
      } else {
        throw new Error("Failed to get data");
      }
    } catch (error) {
      throw error;
    }
  }, [currentLegalEntity.id]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  const handlePlanSelect = (plan: SubscriptionPlan) => {
    segment.track("paywall plan: selected", {
      "subscription family": plan.family.slug,
      "subscription product": plan.product.slug,
      "subscription plan": plan.slug,
    });

    app.openModal({
      name: ModalName.LSCPStandalonePayment,
      extraData: { plan },
      openedBy: {
        context: "userspace standalone pro account",
        from: "plan list",
      },
    });
  };

  const { title, description, isAllowedToSubscribe } =
    getDynamicContent(currentLegalEntity);

  return (
    <>
      <StyledPageContainer>
        <PageContentBody>
          <PageHeader className={isAllowedToSubscribe ? "" : "row-gap-m"}>
            <Title type={isMobile ? "H1" : "H2"}>{title}</Title>

            {isAllowedToSubscribe ? (
              <Text style={{ maxWidth: "664px" }}>{description}</Text>
            ) : (
              <InfoBox>
                <UniversalInprogress size="60" className="icon" />
                <div className="title">
                  <Title type="H3">
                    Immatriculation de votre société en cours{" "}
                  </Title>
                </div>
                <Text className="description">
                  Vous pourrez souscrire au compte pro dès que votre SIREN sera
                  reçu
                </Text>
              </InfoBox>
            )}
          </PageHeader>

          <SubscriptionPlanList
            onPlanSelect={handlePlanSelect}
            plans={monthlyPlansToSell}
            planItemConfigs={{
              disabled: !isAllowedToSubscribe,
            }}
          />
        </PageContentBody>
      </StyledPageContainer>
      {app.openingModal?.name === ModalName.LSCPStandalonePayment && (
        <LSCPStandalonePaymentModal />
      )}
    </>
  );
};

export default PlanSelectionPage;
