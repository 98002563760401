import { LegalEntity } from "types/legal-entities";
import {
  RequestDataCreateLeadFromLegalEntity,
  RequestGetLeadByLegalEntity,
} from "types/partnerships";
import coreApiInstance from "../coreApi";

export const createLeadFromLegalEntity = (
  legalEntityId: LegalEntity["id"],
  data: RequestDataCreateLeadFromLegalEntity
) =>
  coreApiInstance.post(
    `/partners/ls-business-create-partnershiplead/${legalEntityId}/`,
    data,
    {
      validateStatus: (status) => status < 500,
    }
  );

export const getLeadByLegalEntity = (
  pathVariables: RequestGetLeadByLegalEntity["request"]["pathVariables"]
) => {
  const { legalEntityId, partnerSlug, partnerServiceSlug } = pathVariables;
  return coreApiInstance.get<RequestGetLeadByLegalEntity["response"]["data"]>(
    `/partners/${partnerSlug}/leads/legal-entity/${legalEntityId}/partner-service/${partnerServiceSlug}/`
  );
};
