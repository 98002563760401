import { Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { BackButton } from "pages/components";
import SubscriptionPlanList from "pages/components/subscriptions/SubscriptionPlanList";
import { SubscriptionViewPath } from "pages/subscriptions-management/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionsService } from "services";
import { SubscriptionPlan } from "types/subscriptions";
import SubscriptionViewContext from "../SubscriptionViewContext";
import ChangePlanContext, { ChangePlanType } from "./ChangePlanContext";

const getDownOrUp = (
  newPlan: SubscriptionPlan,
  currentPlan: SubscriptionPlan | undefined
) => {
  if (currentPlan?.product.available_upsells.includes(newPlan.product.slug)) {
    return ChangePlanType.Up;
  }
  if (currentPlan?.product.available_downsells.includes(newPlan.product.slug)) {
    return ChangePlanType.Down;
  }
};

const PlanSelectionPage = () => {
  const app = useApp();
  const navigate = useNavigate();

  const changePlanContext = useContext(ChangePlanContext.Context);
  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);
  const { currentSubscription, currentPlan, currentTier, plans } =
    subscriptionViewContext.state;

  const [monthlyPlansToSell, setMonthlyPlansToSell] = useState<
    SubscriptionPlan[] | null
  >(null);

  const _getMonthlyPlansToSell = useCallback(async () => {
    // filter plans to display
    if (plans) {
      const filteredPlans =
        SubscriptionsService.filterMonthlyPlansToSell(plans);

      setMonthlyPlansToSell(filteredPlans);
    }
  }, [plans]);

  useEffect(() => {
    _getMonthlyPlansToSell();
  }, [_getMonthlyPlansToSell]);

  const downOrUpIdentifier = (plan: SubscriptionPlan) =>
    getDownOrUp(plan, currentPlan);

  const handlePlanSelect = (plan: SubscriptionPlan) => {
    const downOrUp = downOrUpIdentifier(plan);

    changePlanContext.dispatch({
      type: ChangePlanContext.ActionType.SetData,
      payload: {
        newPlan: plan,
        downOrUp,
      },
    });

    if (downOrUp === ChangePlanType.Up) {
      app.openModal({
        name: ModalName.SubsMgmntUpsellConfirmation,
        openedBy: {
          context: "change-plan",
          from: "plan-item",
        },
      });
    } else {
      navigate(SubscriptionViewPath.BenefitsComparisonPage);
    }
  };

  return (
    <>
      <BackButton replace />

      <div className="content-container">
        <div className="page_title">
          <Title type="H2">
            Votre abonnement{" "}
            {currentSubscription &&
              SubscriptionsService.getFamilyDisplayName(currentSubscription)}
          </Title>
        </div>

        <SubscriptionPlanList
          currentTier={currentTier}
          onPlanSelect={handlePlanSelect}
          plans={monthlyPlansToSell}
          planItemConfigs={{
            buttonPrefix: "Passer à la formule",
            downOrUpIdentifier,
          }}
          style={{
            justifyContent: "center",
          }}
        />
      </div>
    </>
  );
};

export default PlanSelectionPage;
