import { Button, Column, Text } from "@yolaw/ui-kit-components";
import {
  getSubscriptionResiliationUrl,
  subscriptionDetails,
} from "legalstart-shared-react/constants";
import { memo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import LegacySubscription, {
  LegacySubscriptionStatus,
} from "types/legacy-subscriptions";
import DateTimeUtils from "utils/datetime";
import NumberUtils from "utils/number";
import SubscriptionCard from "./SubscriptionCard";

const ContentContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }

    > :first-child {
      font-weight: bold;
    }

    > :not(:first-child) {
      font-size: 14px;
    }

    > ul {
      padding-left: ${theme.spacing.s}px;
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing.xxxs}px;
      list-style-type: initial;
    }
  `}
`;

export type TLegacySubscriptionWithWebInfo =
  LegacySubscription.SubscriptionItem & {
    webInfo?: WebSubscription;
  };

type TerminationButtonProps = {
  webInfo?: WebSubscription;
};
const TerminationButton = ({ webInfo }: TerminationButtonProps) => {
  if (!webInfo) return null;

  const cancellationURL =
    process.env.REACT_APP_WEB_APP_DOMAIN +
    getSubscriptionResiliationUrl(webInfo.name);

  return (
    <Link to={cancellationURL} target="_blank">
      <Button variant="secondary" size="small">
        {webInfo.user_subscription_data.cancel_at_period_end
          ? "Modifier"
          : "Résilier"}
      </Button>
    </Link>
  );
};

type FooterInfoProps = {
  subscription: TLegacySubscriptionWithWebInfo;
};
const FooterInfo = memo(({ subscription }: FooterInfoProps) => {
  if (!subscription.webInfo) return null;

  const {
    cancel_at_period_end,
    current_period_end,
    plan_amount_in_cents,
    status: webStatus,
  } = subscription.webInfo.user_subscription_data;

  const currentPeriodEndLocale = DateTimeUtils.formatDate(current_period_end);

  if (cancel_at_period_end) {
    return (
      <Text fontWeightVariant="bold">
        Votre demande de résiliation a été prise en compte, vous pouvez
        bénéficier des avantages de votre abonnement jusqu'au{" "}
        {currentPeriodEndLocale}.
      </Text>
    );
  }
  if (["paused", "waiting", "pending"].includes(webStatus)) {
    return (
      <>
        <Text type="body">
          Votre abonnement n'a pas encore été activé. La date du prochain
          prélèvement n'a pas encore été configurée.
        </Text>
        <div className="subs_status hidden">
          <Text type="body">
            Statut de l'abonnement: {subscription.status.toUpperCase()}
          </Text>
        </div>
      </>
    );
  }
  if (subscription.status === LegacySubscriptionStatus.Trialing) {
    return (
      <>
        <Text fontWeightVariant="bold" type="body">
          La période d'essai a démarré. Votre carte sera débitée de{" "}
          {NumberUtils.currencyFormat(plan_amount_in_cents * 0.01)} TTC à la fin
          de celle-ci.
        </Text>
        <Text fontWeightVariant="bold" type="body">
          Votre prochain paiement sera le {currentPeriodEndLocale}.
        </Text>
      </>
    );
  }

  return (
    <>
      <Text fontWeightVariant="bold" type="body">
        Votre carte sera débitée de{" "}
        {NumberUtils.currencyFormat(plan_amount_in_cents * 0.01)} TTC.
      </Text>
      <Text fontWeightVariant="bold" type="body">
        Votre prochain paiement sera le {currentPeriodEndLocale}.
      </Text>
    </>
  );
});

type LegacySubscriptionItemProps = {
  subscription: TLegacySubscriptionWithWebInfo;
};

const LegacySubscriptionItem = memo(
  ({ subscription }: LegacySubscriptionItemProps) => {
    const { webInfo } = subscription;
    const subConf = subscriptionDetails[webInfo?.name || ""] || {};

    return (
      <SubscriptionCard
        header={{
          icon: subConf.iconType,
          title: subscription.name,
          subTitle: webInfo?.subscribed_tagline || "",
        }}
        body={{
          content: (
            <ContentContainer
              dangerouslySetInnerHTML={{
                __html: webInfo?.text || "",
              }}
            />
          ),
          buttons: <TerminationButton webInfo={webInfo} />,
        }}
        footer={<FooterInfo subscription={subscription} />}
      />
    );
  }
);

export default LegacySubscriptionItem;
