import { AppContext } from "contexts";
import { OpeningModal } from "contexts/app";
import { useCallback, useContext } from "react";
import { LinkProps } from "react-router-dom";
import {
  LegalEntityService,
  SubscriptionsService,
  UserService,
} from "services";
import { LSPaymentMethod } from "types";
import LegacySubscription from "types/legacy-subscriptions";
import { LegalEntity } from "types/legal-entities";
import { Subscription } from "types/subscriptions";
import LSUser from "types/user";

export const useApp = () => {
  const { state, dispatch } = useContext(AppContext.Context);

  const openModal = (openingModal: OpeningModal) => {
    dispatch({
      type: AppContext.ActionType.OpenModal,
      payload: openingModal,
    });
  };

  const closeModal = () => {
    dispatch({ type: AppContext.ActionType.CloseModal });
  };

  const setCurrentLegalEntityId = (legalEntityId: LegalEntity["id"]) => {
    dispatch({
      type: AppContext.ActionType.SetCurrentLegalEntityId,
      payload: legalEntityId,
    });
  };

  const setLegacySubscriptions = (
    subscriptions: LegacySubscription.SubscriptionItem[]
  ) => {
    dispatch({
      type: AppContext.ActionType.SetLegacySubscriptions,
      payload: subscriptions,
    });
  };

  const setSubscriptions = (subscriptions: Subscription[]) => {
    dispatch({
      type: AppContext.ActionType.SetSubscriptions,
      payload: subscriptions,
    });
  };

  const setWebSubscriptions = (subscriptions: WebSubscription[]) => {
    dispatch({
      type: AppContext.ActionType.SetWebSubscriptions,
      payload: subscriptions,
    });
  };

  const setUserInfo = (userInfo: LSUser.Info) => {
    dispatch({
      type: AppContext.ActionType.SetUserInfo,
      payload: userInfo,
    });
  };

  const setNavHeaderBackTo = (to: LinkProps["to"]) => {
    dispatch({
      type: AppContext.ActionType.SetNavHeaderBackTo,
      payload: to,
    });
  };

  const setPaymentMethods = (
    paymentMethods: LSPaymentMethod.PaymentMethodObject[]
  ) => {
    dispatch({
      type: AppContext.ActionType.SetPaymentMethods,
      payload: paymentMethods,
    });
  };

  const updateLegalEntity = (legalEntity: LegalEntity) => {
    dispatch({
      type: AppContext.ActionType.UpdateLegalEntity,
      payload: legalEntity,
    });
  };

  /** Refresh subscriptions with latest info  */
  const refreshSubscriptions = async () => {
    const subscriptions = await SubscriptionsService.getSubscriptions();
    setSubscriptions(subscriptions);
  };

  const getPaymentMethods = useCallback(async () => {
    if (state.currentLegalEntityId) {
      const pms = await LegalEntityService.getDefaultPaymentMethods(
        state.currentLegalEntityId
      );
      return pms;
    } else {
      const pm = await UserService.getDefaultPaymentMethod();
      return [pm];
    }
  }, [state.currentLegalEntityId]);

  return {
    ...state,

    // Actions
    openModal,
    closeModal,
    getPaymentMethods,
    setCurrentLegalEntityId,
    setLegacySubscriptions,
    setSubscriptions,
    setWebSubscriptions,
    setUserInfo,
    setNavHeaderBackTo,
    setPaymentMethods,
    updateLegalEntity,
    refreshSubscriptions,
  };
};
