import { Button, Text, Title } from "@yolaw/ui-kit-components";
import { useIsMobile, useSegment } from "hooks";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath, SearchParams } from "services/router";
import { getNextParam } from "../auth-utils";
import NewPasswordFieldset, {
  NewPasswordFieldsetInputs,
  PasswordForm,
} from "../components/NewPasswordFieldset";

const PasswordResetPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(SearchParams.PasswordResetPage.token);

  const formMethods = useForm<NewPasswordFieldsetInputs>({
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  if (!token) {
    throw new Error("Non autorisé !", { cause: "missing_token" });
  }

  const { handleSubmit, formState, setError } = formMethods;

  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: NewPasswordFieldsetInputs) => {
    try {
      const { redirect_url } = await AuthService.passwordResetSave({
        token: token,
        password: data.password,
        next: getNextParam(),
      });

      if (redirect_url) {
        window.location.assign(redirect_url);
      } else {
        searchParams.delete("token");
        navigate(BuiltRoutePath.LoginPage + `?${searchParams.toString()}`, {
          replace: true,
        });
      }
    } catch (error: any) {
      setError("root", { message: error.message || "Something went wrong!" });
    }
  };

  useEffect(() => {
    segment.track("password reset password update form: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <PasswordForm onSubmit={handleSubmit(onSubmit)}>
        <Title
          text="Veuillez définir votre nouveau mot de passe"
          type={isMobile ? "H1" : "H3"}
          color="secondary.main"
        />

        <NewPasswordFieldset
          autoFocus
          labels={{
            password: "Votre nouveau mot de passe",
            confirmPassword: "Confirmez votre nouveau mot de passe",
          }}
        />

        {errors.root && (
          <Text
            type="XSMALL"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}

        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Valider
        </Button>
      </PasswordForm>
    </FormProvider>
  );
};

export default PasswordResetPage;
