import { Button, Container, Row, Text, TextButtonProps } from "@yolaw/ui-kit-components";
import { SvgIconProps } from "@yolaw/ui-kit-icons/types/types/SvgIconProps";
import styled, { css } from "styled-components";

const CTA = styled(Button)``;

const BoxContainer = styled(Container)`
  ${({ theme }) => css`
    --message-color: ${theme.colors.information.dark};

    && {
      align-items: center;
      color: var(--message-color);
      border: 2px solid var(--message-color);
      border-radius: ${theme.borderRadius.s}px;
      background-color: white;
      justify-content: space-between;
      padding: ${theme.spacing.xs}px;

      @media (max-width: ${theme.breakpoints.m}px) {
        justify-content: center;
        ${CTA} {
          flex: 1;
        }
      }
    }
  `}
`;

const Message = styled(Text)``;

const MessageContainer = styled<React.ElementType>(Row)`
  ${({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing.xxxs}px;

    ${Message} {
      flex: 1;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      align-items: flex-start;
    }
  `}
`;

type CtaProps = {
  configs: NonNullable<MessageBoxProps["ctaConfigs"]>;
};
const BoxButton = ({ configs }: CtaProps) => {
  const { text, ...otherConfigs } = configs;
  return (
    <CTA {...otherConfigs} size="small" variant="secondary">
      {text}
    </CTA>
  );
};

type MessageBoxProps = {
  message: string | JSX.Element;
  Icon?: React.FC<SvgIconProps>;
  ctaConfigs?: Pick<TextButtonProps, "onClick" | "icon"> & {
    text: TextButtonProps["children"];
  };
};

const MessageBox = ({ message, Icon, ctaConfigs }: MessageBoxProps) => {
  return (
    <BoxContainer multiline={true}>
      <MessageContainer>
        {Icon && <Icon color="currentColor" />}
        <Message type="BODY" fontWeightVariant="bold">
          {message}
        </Message>
      </MessageContainer>
      {ctaConfigs && <BoxButton configs={ctaConfigs} />}
    </BoxContainer>
  );
};

export default MessageBox;
