import { Button, Column, Tag, Text, Title } from "@yolaw/ui-kit-components";
import React from "react";
import BenefitIcon, {
  BenefitIconType,
} from "services/subscriptions/conf/utils/BenefitIcon";
import { computeBenefitLabel } from "services/subscriptions/conf/utils/product-benefits";
import styled, { css } from "styled-components";
import {
  SubscriptionPlan,
  SubscriptionProductFeatureItem,
  SubscriptionTier,
} from "types/subscriptions";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import BenefitList from "./BenefitList";

const TRANSITION_DURATION = "100ms";

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${theme.spacing.xxxs}px;

    &:not(.disabled) {
      cursor: pointer;
    }

    transition: transform ${TRANSITION_DURATION};
    &:hover {
      transform: translateY(-0.5rem);
    }

    &.highlighted > .content-container {
      border: 1px solid ${theme.colors.accentuation.dark};
      &:hover {
        border: 1px solid ${theme.colors.primary.main};
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-grow: 0;
      flex-basis: 344px;
    }
  `}
`;

const ContentContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    && {
      gap: ${theme.spacing.m}px;
    }
    padding: ${theme.spacing.s}px;

    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};

    transition: border ${TRANSITION_DURATION};
    &:not(:hover) {
      border: 1px solid transparent;
    }

    &:hover {
      border: 1px solid ${theme.colors.primary.main};
    }

    .price {
      display: flex;
      align-items: center;
      column-gap: ${theme.spacing.xxxs}px;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;

      > .tag {
        height: 40px;
        padding-inline: ${theme.spacing.xs}px;
      }
    }

    .benefit_groups_list_container {
      > .benefit_group:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.neutral.lighter};
        padding-bottom: ${theme.spacing.xs}px;
        margin-bottom: ${theme.spacing.xs}px;
      }

      .benefit_group.container {
        display: flex;
        flex-direction: column;
        row-gap: ${theme.spacing.xxxs}px;
      }
    }
  `}
`;

type BenefitItemProps = {
  benefit: SubscriptionProductFeatureItem;
};
const BenefitItem = ({ benefit }: BenefitItemProps) => {
  return (
    <li className="benefit_item">
      <BenefitIcon
        type={
          benefit.contained_in_product
            ? BenefitIconType.Positive
            : BenefitIconType.Negative
        }
      />
      <Text
        type="small"
        color={benefit.is_highlighted ? "primary.dark" : "neutral.dark"}
        fontWeightVariant={benefit.is_highlighted ? "bold" : undefined}
      >
        {computeBenefitLabel(benefit.description, benefit.count)}
      </Text>
    </li>
  );
};

type PlanItemProps = {
  buttonTextPrefix?: string;
  buttonVariant?: "primary" | "secondary";
  currentTier?: SubscriptionTier;
  disabled?: boolean;
  onPlanSelect: (plan: SubscriptionPlan) => void;
  plan: SubscriptionPlan;
  tierToSell: SubscriptionTier;
} & Partial<HTMLDivElement>;

const SubscriptionPlanItem = ({
  buttonTextPrefix = "Choisir la formule",
  buttonVariant = "primary",
  className,
  currentTier,
  disabled: disabledProp,
  onPlanSelect,
  plan,
  tierToSell,
}: PlanItemProps) => {
  const isCurrentTier = currentTier?.id && currentTier.id === tierToSell.id;
  const featureDisplay = plan.product.display?.feature_display;
  const highlightText = plan.product.display?.main_display?.highlight_text;
  const hasTrialPeriod = !!plan.trialing_amount && plan.trialing_amount > 0;

  // TODO: handle disabled
  const isDisabled = disabledProp || !!isCurrentTier;

  const handlePlanSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isDisabled) return;

    onPlanSelect(plan);
  };

  const buttonText = hasTrialPeriod
    ? `Démarrer l'essai gratuit`
    : `${buttonTextPrefix} ${plan.product.name}`;

  return (
    <Container
      className={[
        className,
        highlightText && "highlighted",
        isDisabled && "disabled",
      ]
        .filter(Boolean)
        .join(" ")}
      onClick={handlePlanSelect}
    >
      {highlightText && <Tag color="purple">{highlightText}</Tag>}
      <ContentContainer className="content-container">
        <div className="header">
          <Title type="H4">{plan.product.name.toUpperCase()}</Title>

          <div className="price">
            {hasTrialPeriod ? (
              <Text type="small">
                <Text color="information.dark" fontWeightVariant="bold">
                  {plan.trialing_amount}{" "}
                  {TranslatorUtils.translate(plan.trialing_unit!)} d'essai
                  gratuit
                </Text>
                <Text fontWeightVariant="bold">
                  {" "}
                  puis {NumberUtils.currencyFormat(tierToSell.flat_price)} HT /{" "}
                  {TranslatorUtils.translate(plan.period_unit)} sans engagement
                </Text>
              </Text>
            ) : (
              <>
                <Title type="H3">
                  {NumberUtils.currencyFormat(tierToSell.flat_price)}
                </Title>
                <Text type="small">
                  HT / {TranslatorUtils.translate(plan.period_unit)} sans
                  engagement
                </Text>
              </>
            )}
          </div>
        </div>

        <div className="action">
          {isCurrentTier ? (
            <Tag color="green" size="large" className="tag">
              Votre abonnement actuel
            </Tag>
          ) : (
            <Button
              disabled={isDisabled}
              variant={buttonVariant}
              onClick={handlePlanSelect}
            >
              {buttonText}
            </Button>
          )}
        </div>

        {featureDisplay && (
          <div className="benefit_groups_list_container">
            {featureDisplay.map((g) => (
              <div className="benefit_group container" key={g.name}>
                <Text fontWeightVariant="bold" color="neutral.dark">
                  {g.name}
                </Text>
                <BenefitList className="benefits_list">
                  {g.list_description.map((i) => (
                    <BenefitItem key={i.description} benefit={i} />
                  ))}
                </BenefitList>
              </div>
            ))}
          </div>
        )}
      </ContentContainer>
    </Container>
  );
};

export default SubscriptionPlanItem;
