import { useLegalEntity, useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import FormalitiesListingPage from "pages/formalities/FormalitiesListingPage";
import useLSCPSubscription from "pages/ls-compte-pro/hooks/useLSCPSubscription";
import { useEffect } from "react";
import styled, { css } from "styled-components";
import CompteProSection from "./CompteProSection";
import OnGoingFormalitiesSection from "./OnGoingFormalitiesSection";
import NewShareholderAccountCreatedModal from "./NewShareholderAccountCreatedModal";

const StyledPageContainer = styled(PageContainer)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.m}px;

    .sections {
      display: flex;
      flex-direction: column;
      row-gap: inherit;

      &.--company-registered {
        flex-direction: column-reverse;
      }
    }
  `}
`;

const HomePageContent = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();

  const hasCompanyRegistered = !!currentLegalEntity.siren;

  useEffect(() => {
    segment.track("userspace homepage: displayed", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: lscpSubscription?.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPageContainer>
      <div
        className={`sections ${
          hasCompanyRegistered ? "--company-registered" : ""
        }`}
      >
        <OnGoingFormalitiesSection />
        <CompteProSection hasCompanyRegistered={hasCompanyRegistered} />
      </div>
      <NewShareholderAccountCreatedModal />
    </StyledPageContainer>
  );
};

export const HomePage = () => {
  const lscpSubscription = useLSCPSubscription();

  // no LSCP sub > render <FormalitiesPage />
  if (!lscpSubscription?.isAccessible) {
    return <FormalitiesListingPage />;
  }

  return <HomePageContent />;
};
