import {
  Button,
  Column,
  Hint,
  HintType,
  Row,
  Text,
} from "@yolaw/ui-kit-components";
import { CheckmarkFlat } from "@yolaw/ui-kit-icons";
import { Link } from "react-router-dom";
import { SubscriptionsService } from "services";
import styled, { useTheme } from "styled-components";
import {
  Subscription,
  SubscriptionPlannedAction,
  SubscriptionProduct,
  SubscriptionStatus,
} from "types/subscriptions";
import DateTimeUtils from "utils/datetime";
import NumberUtils from "utils/number";
import { SubscriptionViewPath } from "../router";
import SubscriptionCard from "./SubscriptionCard";

const StyledFooterText = styled(Text).attrs({
  fontWeightVariant: "bold",
  type: "body",
})``;

const DisabledModifyBtn = () => (
  <Button variant="secondary" size="small" disabled>
    Modifier l'abonnement
  </Button>
);

const getButtonsByStatus = (subscription: Subscription): JSX.Element => {
  switch (subscription.status) {
    case SubscriptionStatus.Active:
    case SubscriptionStatus.Draft:
    case SubscriptionStatus.Trialing:
      return (
        <>
          <Link
            to={`${subscription.id}/${SubscriptionViewPath.ChangePlanPage}`}
          >
            <Button variant="secondary" size="small">
              Gérer la formule
            </Button>
          </Link>
          <Link
            to={`${subscription.id}/${SubscriptionViewPath.TerminationPage}`}
          >
            Résilier
          </Link>
        </>
      );
    case SubscriptionStatus.Incomplete:
    case SubscriptionStatus.IncompleteExpired:
    case SubscriptionStatus.PastDue:
    case SubscriptionStatus.Paused:
    case SubscriptionStatus.Canceled:
    case SubscriptionStatus.RenewalFailure:
    case SubscriptionStatus.Unpaid:
    default:
      return <DisabledModifyBtn />;
  }
};

const getFooterByStatus = (subscription: Subscription): JSX.Element => {
  const { next_billing_amount, next_billing_date, trial_end_date, tier } =
    subscription;

  switch (subscription.status) {
    case SubscriptionStatus.Active:
      return (
        <>
          <StyledFooterText>
            Votre prochain prélèvement sera le{" "}
            {DateTimeUtils.formatDate(next_billing_date)}.
          </StyledFooterText>
          <StyledFooterText>
            Votre carte sera débitée de{" "}
            {NumberUtils.currencyFormat(next_billing_amount || 0)}&nbsp;TTC.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.Draft:
      return (
        <>
          <StyledFooterText>
            En attente de la création de votre{" "}
            <strong>
              {SubscriptionsService.getFamilyDisplayName(subscription)}
            </strong>
            , la facturation n'a pas encore démarré.
          </StyledFooterText>
          <StyledFooterText>
            Une fois l'abonnement démarré, vous serez prélevé de{" "}
            {NumberUtils.currencyFormat(tier.total_price_it || 0)}&nbsp;TTC.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.Incomplete:
      return (
        <>
          <StyledFooterText>
            Des informations de paiement sont manquantes.
          </StyledFooterText>
          <StyledFooterText>
            Mettez à jour vos informations de paiement pour activer votre
            abonnement.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.IncompleteExpired:
      return (
        <>
          <StyledFooterText>
            Votre tentative de création d'abonnement a échoué.
          </StyledFooterText>
          <StyledFooterText>
            Relancez le processus pour souscrire à un nouvel abonnement.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.PastDue:
    case SubscriptionStatus.RenewalFailure:
      return (
        <>
          <StyledFooterText>Votre dernier paiement a échoué.</StyledFooterText>
          <StyledFooterText>
            Veuillez mettre à jour votre moyen de paiement pour éviter
            l'interruption de service.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.Paused:
      return (
        <>
          <StyledFooterText>
            Votre abonnement est temporairement suspendu pour défaut de
            paiement.
          </StyledFooterText>
          <StyledFooterText>
            Mettez à jour vos informations de paiement pour le réactiver.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.Trialing:
      return (
        <>
          <StyledFooterText>
            Votre période d'essai se termine le{" "}
            {DateTimeUtils.formatDate(trial_end_date)}.
          </StyledFooterText>
          <StyledFooterText>
            Votre prochain prélèvement sera de{" "}
            {NumberUtils.currencyFormat(tier.total_price_it || 0)}&nbsp;TTC.
          </StyledFooterText>
        </>
      );
    case SubscriptionStatus.Canceled:
    case SubscriptionStatus.Unpaid:
    default:
      return <></>;
  }
};

type ConditionalElements = {
  buttons: JSX.Element;
  footer: JSX.Element;
};
const getConditionalElements = (
  subscription: Subscription
): ConditionalElements => {
  switch (subscription.planned_action) {
    case SubscriptionPlannedAction.CANCEL:
      return {
        buttons: <DisabledModifyBtn />,
        footer: (
          <>
            <StyledFooterText>
              Votre abonnement est en cours de résiliation.
            </StyledFooterText>
            <StyledFooterText>
              Il sera résilié le&nbsp;:{" "}
              {DateTimeUtils.formatDate(
                subscription.cancellation_planned_date!
              )}
            </StyledFooterText>
          </>
        ),
      };
    case SubscriptionPlannedAction.DOWNSELL:
      return {
        buttons: <DisabledModifyBtn />,
        footer: (
          <Hint type={HintType.Information}>
            Le changement de plan a bien été pris en compte. Il sera effectif à
            partir du prochain cycle de facturation.
          </Hint>
        ),
      };
    case SubscriptionPlannedAction.UPSELL: // TODO: handle action
    case SubscriptionPlannedAction.CYCLE_CHANGE: // TODO: handle action
      return {
        buttons: <DisabledModifyBtn />,
        footer: (
          <Hint type={HintType.Information}>
            La modification de votre formule sera effective sous 4 heures
            ouvrées.
          </Hint>
        ),
      };
    default:
      return {
        buttons: getButtonsByStatus(subscription),
        footer: (
          <>
            {getFooterByStatus(subscription)}
            <div className="subs_status hidden">
              <Text>
                Statut de l'abonnement: {subscription.status.toUpperCase()}
              </Text>
            </div>
          </>
        ),
      };
  }
};

const ProductContentContainer = styled<React.ElementType>(Column)`
  row-gap: ${({ theme }) => theme.spacing.xxxs}px;
`;

const FeatureList = styled<React.ElementType>(Column).attrs({ as: "ul" })`
  list-style: none;
  && {
    row-gap: ${({ theme }) => theme.spacing.xxxs}px;
  }
`;

const FeatureItemContainer = styled<React.ElementType>(Row).attrs({ as: "li" })`
  && {
    justify-content: flex-start;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing.xxxs}px;
  }
`;

type FeatureItemProps = {
  label: string;
};
const FeatureItem = ({ label }: FeatureItemProps) => {
  const theme = useTheme();

  return (
    <FeatureItemContainer>
      <CheckmarkFlat color={theme.colors.primary.main} />
      <Text type="small">{label}</Text>
    </FeatureItemContainer>
  );
};

type ProductDescriptionProps = {
  product: SubscriptionProduct;
};
const ProductDescription = ({ product }: ProductDescriptionProps) => {
  const featureList = product.display?.main_display?.list_description;

  return (
    <ProductContentContainer>
      <Text fontWeightVariant="bold">{`Inclus dans le plan ${product.name}`}</Text>
      <FeatureList>
        {featureList?.map((feature) => (
          <FeatureItem key={feature} label={feature} />
        ))}
      </FeatureList>
    </ProductContentContainer>
  );
};

type SubscriptionItemProps = {
  subscription: Subscription;
};

const SubscriptionItem = ({ subscription }: SubscriptionItemProps) => {
  const { buttons, footer } = getConditionalElements(subscription);

  const { icon = "UniversalCurrentAccount", sub_title = "" } =
    subscription.product.display?.main_display || {};

  return (
    <SubscriptionCard
      header={{
        icon: icon,
        title: SubscriptionsService.getFamilyDisplayName(subscription),
        subTitle: sub_title,
      }}
      body={{
        content: <ProductDescription product={subscription.product} />,
        buttons: buttons,
      }}
      footer={footer}
    />
  );
};

export default SubscriptionItem;
