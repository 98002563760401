import { Navigate, Route, Routes } from "react-router-dom";
import { SubscriptionViewPath } from "./router";
import ChangePlanPage from "./subscription-view/change-plan/ChangePlanPage";
import SubscriptionView from "./subscription-view/SubscriptionView";
import TerminationPage from "./subscription-view/termination/TerminationPage";
import SubscriptionsListingPage from "./SubscriptionsListingPage";

export const SubscriptionsManagementApp = () => {
  return (
    <Routes>
      <Route index element={<SubscriptionsListingPage />} />
      <Route
        path={`${SubscriptionViewPath.BasePath}/*`}
        element={<SubscriptionView />}
      >
        {/* No subs details view => back to subs listing */}
        <Route index element={<Navigate to={".."} replace />} />
        {/* Change plan */}
        <Route
          path={`${SubscriptionViewPath.ChangePlanPage}/*`}
          element={<ChangePlanPage />}
        />
        {/* Termination */}
        <Route
          path={SubscriptionViewPath.TerminationPage}
          element={<TerminationPage />}
        />
      </Route>
    </Routes>
  );
};

export default SubscriptionsManagementApp;
