import { CustomHTMLDialogElement } from "components/modal/Modal";
import React from "react";

type State = {
  isSubmitting: boolean;
  modalRef: React.MutableRefObject<CustomHTMLDialogElement | null> | null;
};

const initialState: State = {
  isSubmitting: false,
  modalRef: null,
};

enum Action {
  SetIsSubmitting = "SET_IS_SUBMITTING",
  SetModalRef = "SET_MODAL_REF",
}

type SetIsSubmittingAction = {
  type: Action.SetIsSubmitting;
  payload: State["isSubmitting"];
};

type SetModalRefAction = {
  type: Action.SetModalRef;
  payload: State["modalRef"];
};

type DispatchAction = SetIsSubmittingAction | SetModalRefAction;

const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case Action.SetIsSubmitting:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case Action.SetModalRef:
      return {
        ...state,
        modalRef: action.payload,
      };
    default:
      throw new Error("[PaymentModalContext] unexpected action type");
  }
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

const Context = React.createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "PaymentModalContext";

const PaymentModalContext = {
  Action,
  Context,
  initialState,
  reducer,
};

export default PaymentModalContext;
