import { Column, Hint, HintType, Text, Title } from "@yolaw/ui-kit-components";
import { InformationBubbleFlat } from "@yolaw/ui-kit-icons";
import { useSegment } from "hooks";
import MessageBox from "pages/zen/components/MessageBox";
import ZenUpsellBanner from "pages/zen/components/ZenUpsellBanner";
import { ZenDashboard } from "pages/zen/context";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useZenProject from "../../hooks/useZenProject";
import AdministrativeTaskSection from "./AdministrativeTaskSection";

const Container = styled<React.ElementType>(Column)`
  && {
    row-gap: ${({ theme }) => theme.spacing.l}px;
  }
`;

const sortSections = (sections: Record<string, TaskItem[]>) => {
  // use the origin order from the given sections object as default order
  const defaultOrder = Object.keys(sections);

  const sortedSectionKeys = defaultOrder.sort((sectionA, sectionB) => {
    // Check if all tasks in sectionA are done
    const allTaskDoneA = sections[sectionA].every((task) => task.is_done);
    // Check if all tasks in sectionB are done
    const allTaskDoneB = sections[sectionB].every((task) => task.is_done);

    // If both sections are done, maintain default order
    if (allTaskDoneA && allTaskDoneB) {
      return defaultOrder.indexOf(sectionA) - defaultOrder.indexOf(sectionB);
    }
    // If only sectionA is all done, move it to the end
    else if (allTaskDoneA) {
      return 1;
    }
    // If sectionB is all done, keep it before section A
    else if (allTaskDoneB) {
      return -1;
    }
    // Else, maintain default order
    else {
      return defaultOrder.indexOf(sectionA) - defaultOrder.indexOf(sectionB);
    }
  });

  // Restructure sorted sections object
  const sortedSectionsObj: typeof sections = {};
  sortedSectionKeys.forEach((sectionKey) => {
    sortedSectionsObj[sectionKey] = sections[sectionKey];
  });

  return sortedSectionsObj;
};

const SECTION_NAME: Record<string, string> = {
  remunerationTasks: "Rémunération",
  protectionTasks: "Protection",
  administrativeTasks: "Administratif",
};

const AdministrativeDashboard = () => {
  const project = useZenProject();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const { goToOnboardingQuestionnaire } = useZenRoutes();

  useEffect(() => {
    project.setCurrentDashboard(ZenDashboard.AdministrativeDashboard);

    segment.track("zen administrative dashboard: displayed", {
      from: searchParams.get("utm_source") || "userspace",
      project_id: project.info.id,
      has_siren: project.hasCompanySiren,
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderedSections = sortSections(project.administrativeTasks);

  const handleUpdateOnboardingAnswersCtaClick = () => {
    goToOnboardingQuestionnaire({
      searchParams: "?utm_source=dashboard",
      navigateOptions: {
        state: { startFromBeginning: true },
      },
    });
  };

  return (
    <Container>
      <Title type="H1">Optimisation de votre société</Title>

      {project.isZenSubscriptionNeeded ? (
        <ZenUpsellBanner />
      ) : !project.hasCompanySiren ? (
        <Hint type={HintType.Information}>
          <Text fontWeightVariant="bold">
            Certaines tâches seront accessibles une fois votre SIREN reçu.
          </Text>
        </Hint>
      ) : null}

      {Object.entries(orderedSections).map(([key, value], index) => (
        <Fragment key={key}>
          <AdministrativeTaskSection title={SECTION_NAME[key]} tasks={value} />
          {index === 0 ? (
            <MessageBox
              message={
                "Votre situation a changé ? Mettez à jour vos informations pour réadapter vos tâches."
              }
              Icon={InformationBubbleFlat}
              ctaConfigs={{
                text: "Mettre à jour",
                onClick: handleUpdateOnboardingAnswersCtaClick,
              }}
            />
          ) : null}
        </Fragment>
      ))}
    </Container>
  );
};

export default AdministrativeDashboard;
