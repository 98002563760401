import { ChevronDownFlat } from "@yolaw/ui-kit-icons";
import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { css, styled } from "styled-components";

import { getNavigationLinkBaseStyles, NavigationLinkLabel } from "./helper";

const NavigationLinkIcon = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  width: var(--nav-item-icon-width);
  height: 24px;

  > svg {
    margin: auto;
  }
`;

type MultiLevelNavigationToggleIconProps = {
  $isOpen: boolean;
};

const MultiLevelNavigationToggleIcon = styled(
  NavigationLinkIcon
)<MultiLevelNavigationToggleIconProps>`
  margin-left: auto;
  transition: rotate 0.3s;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      rotate: 180deg;
    `};
`;

const MultiLevelNavigationLink = styled.button`
  ${getNavigationLinkBaseStyles()};

  width: 100%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

type MultiLevelNavigationListBoxProps = {
  $isOpen: boolean;
};

const MultiLevelNavigationListBox = styled.div<MultiLevelNavigationListBoxProps>`
  grid-template-rows: 0fr;
`;

const MainContainer = styled.li`
  > ${MultiLevelNavigationListBox}[inert] {
    grid-template-rows: 0fr;
    margin-top: 0;
  }
  > ${MultiLevelNavigationListBox} {
    display: grid;
    grid-template-rows: 1fr;
    margin-top: ${(props) => props.theme.spacing.s}px;
    transition: all 0.3s;
  }
`;

type MultiLevelNavigationItemProps = {
  label: string | ReactElement;
  icon: JSX.Element;
  children: ReactNode;
  className?: string;
  open?: boolean;
};

const MultiLevelNavigationItem: FC<MultiLevelNavigationItemProps> = ({
  label,
  icon,
  children,
  className,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const location = useLocation();
  const ref = useRef<HTMLLIElement>(null);

  const hasActiveChild = () =>
    !!ref.current?.querySelector(".nav-item-link.active");

  const onMultiLevelNavigationLinkClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (hasActiveChild() && !isOpen) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const elm = ref.current?.getElementsByClassName("nav-multi-list-items")[0];
    if (isOpen) {
      elm?.removeAttribute("inert");
    } else {
      elm?.setAttribute("inert", "");
    }
  }, [isOpen]);

  return (
    <MainContainer ref={ref} className={className}>
      <MultiLevelNavigationLink onClick={onMultiLevelNavigationLinkClick}>
        <NavigationLinkIcon>{icon}</NavigationLinkIcon>
        <NavigationLinkLabel>{label}</NavigationLinkLabel>
        <MultiLevelNavigationToggleIcon $isOpen={isOpen}>
          <ChevronDownFlat size="16" />
        </MultiLevelNavigationToggleIcon>
      </MultiLevelNavigationLink>
      <MultiLevelNavigationListBox
        className="nav-multi-list-items"
        $isOpen={isOpen}
      >
        {children}
      </MultiLevelNavigationListBox>
    </MainContainer>
  );
};

export default React.memo(MultiLevelNavigationItem);
