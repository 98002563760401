import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { Button } from "@yolaw/ui-kit-components";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import useStripePayment from "hooks/useStripePayment";
import { useContext } from "react";
import { PaymentOption } from "services/payment";
import PaymentModalContext from "./Context";

type Props = {
  disabled: boolean;
  isLoading: boolean;
  onPaymentMethodCollected: (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent,
    skipPaymentMethodSetup?: boolean
  ) => void;
  buttonText?: string;
};
const PayWithCardButton = ({
  buttonText,
  disabled,
  isLoading,
  onPaymentMethodCollected,
}: Props) => {
  const paymentModalContext = useContext(PaymentModalContext.Context);
  const { createStripePaymentMethod } = useStripePayment();

  const paymentForm = usePaymentFormContext();
  const { cardHolderName, selectedCard, paymentOption } = paymentForm.state;

  const createNewPaymentMethod = async () => {
    try {
      paymentModalContext.dispatch({
        type: PaymentModalContext.Action.SetIsSubmitting,
        payload: true,
      });

      const { error, paymentMethod: stripePaymentMethod } =
        await createStripePaymentMethod(undefined, {
          card_holder_name: cardHolderName,
        });

      if (stripePaymentMethod) {
        onPaymentMethodCollected(stripePaymentMethod.id);
      } else {
        throw error;
      }
    } catch (error: any) {
      paymentForm.action.setPaymentError({
        source: "ERROR_SOURCES.FRONTEND_STRIPE",
        message:
          error.message || "Échec de la création d'un moyen de paiement.",
        code: String(error.code),
      });

      paymentModalContext.dispatch({
        type: PaymentModalContext.Action.SetIsSubmitting,
        payload: false,
      });
    }
  };

  const handleClick = async () => {
    if (paymentOption === PaymentOption.UseSavedCard && selectedCard) {
      onPaymentMethodCollected(selectedCard.payment_method_id, undefined, true);
    } else {
      createNewPaymentMethod();
    }
  };

  return (
    <Button disabled={disabled} isLoading={isLoading} onClick={handleClick}>
      {buttonText || "M’abonner"}
    </Button>
  );
};

export default PayWithCardButton;
