import GooglePayButtonReact from "@google-pay/button-react";
import { Button } from "@yolaw/ui-kit-components";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import useStripePayment from "hooks/useStripePayment";
import GooglePayProvider from "providers/googlePay";

type Props = {
  disabled: boolean;
  isLoading: boolean;
  onPaymentMethodCollected: (stripePaymentMethodId: string) => void;
};
const PayWithGooglePayBtn = ({
  disabled,
  isLoading,
  onPaymentMethodCollected,
}: Props) => {
  const { createStripePaymentMethod } = useStripePayment();
  const paymentForm = usePaymentFormContext();

  const handleButtonClick = (event: Event) => {
    // Try to validate payment information
    if (disabled) {
      // If the validation is not pass
      // => prevent the Google payment sheets to be opened
      event.preventDefault();
    }
  };

  const onPaymentAuthorized = async (
    paymentData: google.payments.api.PaymentData
  ): Promise<google.payments.api.PaymentAuthorizationResult> => {
    const paymentTokenId = JSON.parse(
      paymentData.paymentMethodData.tokenizationData.token
    ).id;

    if (paymentTokenId) {
      const { error, paymentMethod: stripePaymentMethod } =
        await createStripePaymentMethod(
          { token: paymentTokenId },
          { wallet_name: "google_pay" }
        );

      if (error) {
        paymentForm.action.setPaymentError({
          source: "ERROR_SOURCES.FRONTEND_STRIPE",
          message:
            error.message || "Échec de la création d'un mode de paiement.",
          code: String(error.code),
        });
      }

      if (stripePaymentMethod) {
        onPaymentMethodCollected(stripePaymentMethod.id);
      }

      // close Google Pay payment sheet
      return {
        transactionState: "SUCCESS",
      };
    }

    // close Google Pay payment sheet
    return {
      transactionState: "ERROR",
    };
  };

  if (isLoading) {
    return <Button isLoading={true}>GPay</Button>;
  }

  return (
    <GooglePayButtonReact
      buttonLocale="fr"
      buttonType={"plain"}
      environment={
        process.env.REACT_APP_ENVIRONMENT_NAME === "production"
          ? "PRODUCTION"
          : "TEST"
      }
      paymentRequest={GooglePayProvider.getGooglePaymentDataRequest()}
      onClick={handleButtonClick}
      onPaymentAuthorized={onPaymentAuthorized}
      buttonSizeMode="fill"
      style={{ height: "50px", opacity: disabled ? 0.6 : 1 }}
    />
  );
};

export default PayWithGooglePayBtn;
