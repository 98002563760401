import {
  Button,
  Checkbox,
  Column,
  Label,
  Row,
  Text,
  toast,
} from "@yolaw/ui-kit-components";
import { useLegalEntity, useSegment } from "hooks";
import useLSCPSubscription from "pages/ls-compte-pro/hooks/useLSCPSubscription";
import React, { useState } from "react";
import { ApiService } from "services";
import { LSCPRoutePath } from "services/router";
import styled, { css } from "styled-components";
import {
  CompteProCompanyStatus,
  CompteProTaskSlug,
  CompteProTaskStatus,
} from "../../../types/compte-pro";
import { getCompteProCompanyStatus } from "../compte-pro-utils";

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: ${theme.spacing.xxxs}px;
    column-gap: ${theme.spacing.xs}px;

    background-color: white;
    border-radius: ${theme.borderRadius.s}px;
    border: 1px solid ${theme.colors.neutral.lightest};

    padding: ${theme.spacing.xs}px;

    &.disabled {
      color: ${theme.colors.neutral.lighter};
    }

    .checkbox {
      flex-shrink: 0;
      padding: 0;
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      > .task-access-btn {
        align-self: center;
      }
    }
  `}
`;

// prettier-ignore
const ToastContent = styled<React.ElementType>(Column).withConfig({shouldForwardProp: (prop) => !["toastProps", "closeToast"].includes(prop)})`
  ${({ theme }) => css`
    align-items: flex-start;
    row-gap: ${theme.spacing.xxxs}px;
  `}
`;

type TaskContentConf = {
  title: string;
  description: string;
  lsBizPath: LSCPRoutePath;
  confirmMessage: string;
};
const TASK_CONTENT: Record<CompteProTaskSlug, TaskContentConf> = {
  [CompteProTaskSlug.ActivateCard]: {
    title: "Commander et activer ma carte de paiement",
    description:
      "Commandez gratuitement votre carte bancaire dès maintenant et activez-la rapidement pour anticiper vos dépenses.",
    lsBizPath: LSCPRoutePath.ProAccount,
    confirmMessage: "Avez-vous commandé et activé votre carte bancaire ?",
  },
  [CompteProTaskSlug.SettingInvoices]: {
    title: "Paramétrer ma première facturation",
    description:
      "Préparez-vous à facturer vos premiers clients en configurant vos paramètres de facturation dès maintenant.",
    lsBizPath: LSCPRoutePath.GenerateInvoice,
    confirmMessage: "Avez-vous paramétré votre première facturation ?",
  },
};

type Props = {
  slug: CompteProTaskSlug;
};

const CompteProTask = ({ slug }: Props) => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();

  const initiallyDone =
    currentLegalEntity.compte_pro_tasks?.find((t) => t.task_slug === slug)
      ?.status === CompteProTaskStatus.Done;

  const [isDone, setIsDone] = useState(initiallyDone);

  if (initiallyDone) {
    // task won't be rendered if it's already done before
    return null;
  }

  const { title, description, confirmMessage, lsBizPath } = TASK_CONTENT[slug];

  const ssoURL = lscpSubscription?.buildSSORedirectionURL(lsBizPath);
  const compteProCompanyStatus = getCompteProCompanyStatus(currentLegalEntity);
  const disabled =
    compteProCompanyStatus !== CompteProCompanyStatus.Active || !ssoURL;
  const checkboxId = `${slug}-checkbox`;
  const toastId = `${slug}-toast`;

  const updateTask = (done: boolean) => {
    if (done) {
      segment.track("ls compte pro task: completed", {
        le_id: currentLegalEntity.id,
        task_name: title,
      });
    }

    // Local state update
    setIsDone(done);
    toast.dismiss(toastId);

    // API call to save the task status in BE
    ApiService.legalEntities.updateCompteProTask(
      currentLegalEntity.id,
      slug,
      done ? CompteProTaskStatus.Done : CompteProTaskStatus.NotDone
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTask(event.target.checked);
  };

  const simulateCheckBoxClick = () => {
    const checkboxElm = document.getElementById(checkboxId) as HTMLInputElement;
    if (checkboxElm) {
      checkboxElm.click();
    }
  };

  const handleLSCPAccess = () => {
    if (ssoURL) {
      window.open(ssoURL);
    }

    segment.track("ls compte pro task: clicked", {
      le_id: currentLegalEntity.id,
      task_name: title,
    });

    if (!toast.isActive(toastId)) {
      toast.info(
        <ToastContent>
          <Text fontWeightVariant="bold">{confirmMessage}</Text>
          <Button size="small" onClick={simulateCheckBoxClick}>
            Oui c’est fait !
          </Button>
        </ToastContent>,
        {
          toastId: toastId,
          autoClose: false,
          closeOnClick: false,
        }
      );
    }
  };

  return (
    <Container className={disabled ? "disabled" : ""}>
      <Row style={{ alignItems: "flex-start" }}>
        <Checkbox
          id={checkboxId}
          disabled={disabled}
          onChange={handleCheckboxChange}
        />
        <div>
          <Label sizeVariant="LARGE">{title}</Label>
          <Text type="XSMALL">{description}</Text>
        </div>
      </Row>
      {/* <div style={{ alignSelf: "center" }}> */}
      <Button
        size="small"
        variant="secondary"
        disabled={disabled || isDone}
        onClick={handleLSCPAccess}
        className="task-access-btn"
      >
        {isDone ? "Fait !" : "Commencer"}
      </Button>
      {/* </div> */}
    </Container>
  );
};

export default CompteProTask;
