import { useLegalEntity } from "hooks";
import { SubscriptionCategory, SubscriptionStatus } from "types/subscriptions";
import { LSCompteProWaitingForSirenPage } from "./capital-deposit";
import useLSCPSubscription from "./hooks/useLSCPSubscription";
import LSCPStandaloneAccountActivationTimelinePage from "./standalone/AccountActivationTimelinePage";
import LSCPStandalonePlanSelectionPage from "./standalone/PlanSelectionPage";
import LSCPStandaloneUpsellPage from "./standalone/UpsellPage";

const LSCompteProApp = () => {
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();

  if (lscpSubscription) {
    if (lscpSubscription.status !== SubscriptionStatus.Canceled) {
      const needHigherProduct: boolean =
        lscpSubscription.needHigherProductLevel(window.location.pathname);

      if (needHigherProduct) {
        return <LSCPStandaloneUpsellPage />;
      }
    }

    if (lscpSubscription.category === SubscriptionCategory.Standalone)
      return <LSCPStandaloneAccountActivationTimelinePage />;
  }

  if (currentLegalEntity.isEligibleForLSCPStandalone) {
    return <LSCPStandalonePlanSelectionPage />;
  }

  return <LSCompteProWaitingForSirenPage />;
};

export default LSCompteProApp;
