import { Navigate, Route, Routes } from "react-router-dom";

import { RoutePath } from "services/router";
import ZenAppProvider from "./ZenAppProvider";
import ZenProjectInitializer from "./ZenProjectInitializer";
import WaitingSirenModal from "./components/Modals/WaitingSirenModal";
import ZenSubscriptionPaymentModal from "./components/Modals/ZenSubscriptionPayment";
import DashboardLayout from "./layout/Dashboard";
import AdministrativeDashboard from "./pages/administrative-dashboard";
import LegalObligationsDashboard from "./pages/legal-obligations";
import Onboarding from "./pages/onboarding";
import TaskView from "./pages/task-view/TaskView";

const ZenApp = () => {
  return (
    <ZenAppProvider>
      <Routes>
        <Route element={<ZenProjectInitializer />}>
          <Route
            index
            element={<Navigate to={RoutePath.LegalsObligations} replace />}
          />
          <Route element={<DashboardLayout />}>
            <Route
              path={RoutePath.LegalsObligations}
              element={<LegalObligationsDashboard />}
            />
            <Route
              path={RoutePath.AdministrativeDashboard}
              element={<AdministrativeDashboard />}
            />
          </Route>
          <Route path=":dashboardType">
            <Route path="task/:taskId/*" element={<TaskView />} />
            <Route path="onboarding/*" element={<Onboarding />} />
            <Route path="*" element={<Navigate to={"."} />} />
          </Route>
        </Route>
      </Routes>

      {/* Zen modals */}
      <WaitingSirenModal />
      <ZenSubscriptionPaymentModal />
    </ZenAppProvider>
  );
};

export default ZenApp;
