import { findLegalEntityPaymentMethod } from "pages/payment-methods/utilities";
import { useCallback, useEffect, useMemo } from "react";

import {
  LegacySubscriptionService,
  LegalEntityService,
  ServicesService,
  SubscriptionsService,
} from "services";
import { SubscriptionFilters } from "services/subscriptions";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import {
  LegalEntity,
  LegalStructure,
  LegalStructureType,
} from "types/legal-entities";
import {
  LSCPAccessibleStatuses,
  LSCPProductLine,
  SubscriptionFamilySlug,
} from "types/subscriptions";
import { useApp } from "./useApp";
import useUser from "./useUser";

const LegalStructureTypeMap: Record<LegalStructureType, LegalStructure[]> = {
  [LegalStructureType.Association]: [LegalStructure.Association],
  [LegalStructureType.Commercial]: [
    LegalStructure.SAS,
    LegalStructure.SASU,
    LegalStructure.EURL,
    LegalStructure.SARL,
  ],
  [LegalStructureType.RealEstate]: [LegalStructure.SCI],
  [LegalStructureType.MicroOrAutoEnterprise]: [
    LegalStructure.IndividualEnterprise,
    LegalStructure.AutoEnterprise,
  ],
};

const EMPTY_LEGAL_ENTITY: LegalEntity = {
  funnel_id: null,
  id: 0,
  legal_structure: "" as LegalStructure,
  name: "",
  workflow_id: null,
};

type Options = {
  needOwner?: boolean;
  needKDep?: boolean;
};

export type LegalEntityHookReturn = ReturnType<typeof useLegalEntity>;

/** Enhance the Legal Entity object.
 * The App's current LegalEntity is used if the `legalEntity` params is not provided.
 */
export const useLegalEntity = (
  legalEntityArgs?: LegalEntity | null,
  options?: Options
) => {
  const app = useApp();
  const user = useUser();

  const currentLegalEntity = app.legalEntities?.find(
    (le) => le.id === app.currentLegalEntityId
  );
  const legalEntityObj =
    legalEntityArgs || currentLegalEntity || EMPTY_LEGAL_ENTITY;

  const { id, legal_structure, has_access_to_zen, service_id_for_zen } =
    legalEntityObj;

  const getLEOwner = useCallback(async () => {
    if (legalEntityObj.id && !legalEntityObj?.owner) {
      const fullLE = await LegalEntityService.getLegalEntityDetails(
        legalEntityObj.id
      );
      app.updateLegalEntity(fullLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntityObj.id, legalEntityObj?.owner]);

  useEffect(() => {
    if (options?.needOwner) {
      getLEOwner();
    }
  }, [options?.needOwner, getLEOwner]);

  const getMyLEKDep = useCallback(async () => {
    if (legalEntityObj.id && !legalEntityObj?.myKDep) {
      const kDepData = await LegalEntityService.getCapitalDeposit(
        legalEntityObj.id
      );
      app.updateLegalEntity({ ...legalEntityObj, myKDep: kDepData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntityObj.id, legalEntityObj?.myKDep]);

  useEffect(() => {
    if (options?.needKDep) {
      getMyLEKDep();
    }
  }, [options?.needKDep, getMyLEKDep]);

  // TODO: user.id could be more reliable?
  const isLegalEntityOwner = user.email === legalEntityObj.owner?.email;

  /** Check whether the legal structure is the same as the given legal structure */
  const isLegalStructure = (legalStructure: LegalStructure) =>
    legal_structure.toUpperCase() === legalStructure;

  /** Check whether the legal structure is of a type/group */
  const isLegalStructureType = useCallback(
    (type: LegalStructureType) =>
      LegalStructureTypeMap[type].includes(
        legal_structure.toUpperCase() as LegalStructure
      ),
    [legal_structure]
  );

  /** Get the legal structure type */
  const legalStructureType = useMemo(() => {
    return Object.keys(LegalStructureTypeMap).find((type) =>
      isLegalStructureType(type as unknown as LegalStructureType)
    ) as LegalStructureType | undefined;
  }, [isLegalStructureType]);

  const isOneOfLegalStructureTypes = (types: LegalStructureType[]) =>
    types.some((type) => isLegalStructureType(type));

  const leSubscriptions = (app.subscriptions || [])?.filter(
    (sub) => sub.legal_entity_id === id
  );

  const leLegacySubscriptions = (app.legacySubscriptions || []).filter(
    (sub) => sub.legal_entity_id === id
  );

  /** Find ACTIVE subscription */
  const findSubscription = useCallback(
    (subFamilySlug: SubscriptionFamilySlug, filters?: SubscriptionFilters) =>
      SubscriptionsService.findSubscription(
        leSubscriptions,
        subFamilySlug,
        filters
      ),
    [leSubscriptions]
  );

  /** Find ACTIVE legacy subscription (NOTE: with assumption that backend only provides active subscriptions) */
  const findLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ) =>
    LegacySubscriptionService.findLegacySubscription(
      leLegacySubscriptions,
      subSlugOrPrefix
    );

  const findCSActiveSubscription = () =>
    findLegacySubscription("comptastart-") ||
    findLegacySubscription(LegacySubscriptionSlug.WebComptastart);

  const findSOJActiveSubscription = () =>
    findLegacySubscription(LegacySubscriptionSlug.SOJ);

  /** LE has subscribed a legacy subscription and the subscription is still active */
  const hasSubscribedLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ): boolean => !!findLegacySubscription(subSlugOrPrefix);

  const zenSub = findLegacySubscription(LegacySubscriptionSlug.Zen);

  const getZenAccessURL = useCallback(async () => {
    // Try getting the redirection URL from the subscription
    if (zenSub?.redirection_url) {
      return zenSub.redirection_url;
    }

    // In case there is no subscription, try getting the URL from BE
    else if (has_access_to_zen && service_id_for_zen) {
      const accessURL = await ServicesService.getZenAccessURL(
        service_id_for_zen
      );
      // Check again the availability of the subscription redirection URL
      // because it could be set during this request is being performed
      if (zenSub?.redirection_url) {
        return zenSub.redirection_url;
      } else {
        return accessURL;
      }
    }

    // Remove the previous access URL if any
    else {
      return null;
    }
  }, [has_access_to_zen, service_id_for_zen, zenSub?.redirection_url]);

  const paymentMethod = findLegalEntityPaymentMethod(app.paymentMethods);

  /** Find accessible LS Compte Pro subscription */
  const lscpAccessibleSubscription = useMemo(() => {
    return findSubscription(SubscriptionFamilySlug.LSBusiness, {
      statuses: LSCPAccessibleStatuses,
    });
  }, [findSubscription]);

  /** Check whether the legal entity is eligible for LSCP standalone */
  const isEligibleForLSCPStandalone = useMemo(() => {
    return (
      (isLegalStructureType(LegalStructureType.Commercial) ||
        isLegalStructureType(LegalStructureType.MicroOrAutoEnterprise)) &&
      // legalEntityObj.is_legacy === false && // TODO: should strictly check for legacy?
      !legalEntityObj.is_legacy &&
      // lscpSubscription?.category !== SubscriptionCategory.CapitalDeposit, // TODO: check whether it's a valid condition
      !lscpAccessibleSubscription
    );
  }, [
    isLegalStructureType,
    legalEntityObj.is_legacy,
    lscpAccessibleSubscription,
  ]);

  /** Get the eligible LSCP product line */
  const eligibleLSCPProductLine = useMemo(() => {
    switch (legalStructureType) {
      case LegalStructureType.Commercial:
        return LSCPProductLine.CompteProLegalstart;
      case LegalStructureType.MicroOrAutoEnterprise:
        return LSCPProductLine.LegalstartOne;
      default:
        return null;
    }
  }, [legalStructureType]);

  return {
    ...legalEntityObj,
    getZenAccessURL,
    isLegalEntityOwner,

    // Company type (aka legal structure) checks
    isLegalStructure,
    isLegalStructureType,
    isAssociationType: isLegalStructureType(LegalStructureType.Association),
    isCommercialType: isLegalStructureType(LegalStructureType.Commercial),
    isRealEstateType: isLegalStructureType(LegalStructureType.RealEstate),
    isMicroOrAutoEnterpriseType: isLegalStructureType(
      LegalStructureType.MicroOrAutoEnterprise
    ),
    isOneOfLegalStructureTypes,

    paymentMethod,

    // Subscription finder methods
    findSubscription,
    // Subscriptions
    subscriptions: leSubscriptions,

    // LSCP
    isEligibleForLSCPStandalone,
    eligibleLSCPProductLine,

    // Legacy subscription finder methods
    findLegacySubscription,
    findCSActiveSubscription,
    findSOJActiveSubscription,
    // Subscription checker methods
    hasSubscribedLegacySubscription,
    hasSubscribedCS: !!findCSActiveSubscription(),
    hasSubscribedSOJ: !!findSOJActiveSubscription(),
    hasSubscribedSM:
      hasSubscribedLegacySubscription(
        LegacySubscriptionSlug.SurveillanceMarque
      ) ||
      hasSubscribedLegacySubscription(
        LegacySubscriptionSlug.WebSurveillanceMarque
      ),
    // Legacy subscriptions
    legacySubscriptions: leLegacySubscriptions,
    zenSub,
  };
};
