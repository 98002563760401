import { Column, Text } from "@yolaw/ui-kit-components";
import { CheckmarkFlat } from "@yolaw/ui-kit-icons";
import styled, { css, useTheme } from "styled-components";

import { TTimelineElementStatus, TTimelineSection } from "./timeline.types";

const BULLET_SIZE = "26px";
const VERTICAL_LINE_WIDTH = "2px";

const ListItemInnerContainer = styled.div<{ $index: number }>`
  ${({ $index, theme }) => css`
    position: relative;

    display: inline-flex;
    align-items: center;
    column-gap: ${theme.spacing.xs}px;

    width: 100%;

    &::before {
      content: "";
      position: absolute;
      left: calc((${BULLET_SIZE} / 2) - (${VERTICAL_LINE_WIDTH} / 2));
      width: ${VERTICAL_LINE_WIDTH};
      height: calc(110% + ${theme.spacing.s}px);
      z-index: -${$index};
      bottom: 50%;
      background-color: currentColor;
    }
  `}
`;

type ListItemProps = {
  $status: TTimelineElementStatus;
};

const ListItem = styled.li<ListItemProps>`
  max-width: 480px;

  color: ${({ $status, theme }) =>
    $status === TTimelineElementStatus.Locked
      ? theme.colors.neutral.light
      : theme.colors.primary.main};

  &:first-child ${ListItemInnerContainer}::before {
    height: 50%;
  }
`;

type BulletProps = {
  status: TTimelineElementStatus;
};

const Bullet = styled.span<BulletProps>`
  ${({ status }) => css`
    counter-increment: step;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: ${BULLET_SIZE};
    height: ${BULLET_SIZE};
    border-radius: 50%;
    border: 4px solid currentColor;
    background-color: ${status === TTimelineElementStatus.Active
      ? "white"
      : "currentColor"};
    z-index: 1;
  `}
`;

const ContentContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    background-color: white;
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.m}px;
    padding: ${theme.spacing.xs}px;
    row-gap: ${theme.spacing.xxxs}px;
    flex: 1;
  `}
`;

const ButtonWrapper = styled.div`
  display: contents;
  @container (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: flex;
    justify-content: flex-end;
  }
`;

type TimelineSectionProps = {
  section: TTimelineSection;
  index: number;
};

const TimelineSection: React.FC<TimelineSectionProps> = ({
  section,
  index,
}) => {
  const theme = useTheme();

  const { title, status, description, button } = section;

  return (
    <ListItem $status={status}>
      <ListItemInnerContainer $index={index}>
        <Bullet status={status}>
          {status === TTimelineElementStatus.Done && (
            <CheckmarkFlat color={theme.colors.common.white} />
          )}
        </Bullet>
        <ContentContainer>
          <Text fontWeightVariant="bold" color="secondary.main">
            {title}
          </Text>
          <Text color="neutral.dark" type="small">
            {description}
          </Text>
          {button && <ButtonWrapper>{button}</ButtonWrapper>}
        </ContentContainer>
      </ListItemInnerContainer>
    </ListItem>
  );
};

export default TimelineSection;
