import { LSPaymentMethod } from "types";
import DateTimeUtils from "utils/datetime";

const _findPaymentMethodByCardProcessorType = (
  paymentMethods: LSPaymentMethod.PaymentMethodObject[] | null,
  cardProcessorType: LSPaymentMethod.CardProcessorType
) => paymentMethods?.find((pm) => pm.card_processor_type === cardProcessorType);

export const findLegalEntityPaymentMethod = (
  paymentMethods: LSPaymentMethod.PaymentMethodObject[] | null
) =>
  _findPaymentMethodByCardProcessorType(
    paymentMethods,
    LSPaymentMethod.CardProcessorType.Company
  );

export const findUserPaymentMethod = (
  paymentMethods: LSPaymentMethod.PaymentMethodObject[] | null
) =>
  _findPaymentMethodByCardProcessorType(
    paymentMethods,
    LSPaymentMethod.CardProcessorType.User
  );

export enum CardWarning {
  Expired = "expired",
  ExpiresSoon = "expires_soon",
}

export const getCardWarning = (
  exp_month: number | undefined,
  exp_year: number | undefined
) => {
  if (!exp_month || !exp_year) return null;

  const expDate = new Date();
  expDate.setFullYear(exp_year, exp_month);
  const monthDiff = DateTimeUtils.getMonthsDifference(expDate);

  return monthDiff < 0
    ? CardWarning.Expired
    : monthDiff < 2
    ? CardWarning.ExpiresSoon
    : null;
};
