import {
  Button,
  Checkbox,
  Column,
  Spinner,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useSegment } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { SubscriptionsService } from "services";
import styled, { css } from "styled-components";
import Subscription, {
  ProratedAmountResponseData,
  SubscriptionPlan,
  SubscriptionStatus,
  SubscriptionTier,
} from "types/subscriptions";
import DateTimeUtils from "utils/datetime";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";

const InnerContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.m}px;
    > .checkbox_container {
      display: flex;
      align-items: flex-start;
      column-gap: ${theme.spacing.xxxs}px;

      > div {
        padding: unset;
      }
    }
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.s}px;
    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column-reverse;
    }
  `}
`;

type ContentByStatus = {
  content: JSX.Element;
  title?: string;
};
const getContentByStatus = (
  subscription: Subscription | undefined,
  newPlan: SubscriptionPlan | undefined,
  newTier: SubscriptionTier | undefined,
  extraData: ProratedAmountResponseData | undefined
): ContentByStatus => {
  switch (subscription?.status) {
    case SubscriptionStatus.Active:
      return {
        title: "Changer de plan ?",
        content: extraData?.prorata_amount ? (
          <>
            <Text type="body">
              Le changement sera effectif dans la journée, vous serez prélevé de{" "}
              <strong>
                {NumberUtils.currencyFormat(extraData.prorata_amount)}
                &nbsp;TTC
              </strong>
              , proportionnel à la période jusqu’au{" "}
              <strong>
                {DateTimeUtils.formatDate(subscription.current_period_end_date)}
              </strong>
              , sera réalisé.
            </Text>
            <Text type="body">
              À partir du{" "}
              <strong>
                {DateTimeUtils.formatDate(subscription.current_period_end_date)}
              </strong>
              , vous serez facturé(e) mensuellement{" "}
              <strong>
                de {NumberUtils.currencyFormat(newTier?.flat_price || 0)}
                &nbsp;HT soit{" "}
                {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
                &nbsp;TTC
              </strong>
              .
            </Text>
          </>
        ) : (
          <div style={{ width: 40, alignSelf: "center" }}>
            <Spinner />
          </div>
        ),
      };
    case SubscriptionStatus.Draft:
      return {
        content: (
          <Text>
            Une fois votre{" "}
            <strong>
              {SubscriptionsService.getFamilyDisplayName(subscription)}
            </strong>{" "}
            activé, vous serez sur la formule{" "}
            <strong>{newPlan?.product.name}</strong>. Vous serez prélevé(e) de{" "}
            <strong>
              {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
              &nbsp;TTC par{" "}
              {TranslatorUtils.translate(newPlan?.period_unit || "month")}
            </strong>
            .
          </Text>
        ),
      };
    case SubscriptionStatus.Trialing:
      return {
        content: (
          <>
            <Text type="body">
              Vous bénéficiez actuellement de la période d'essai pour cet
              abonnement.
            </Text>
            <Text type="body">
              Le changement de plan sera effectif dans la journée.
            </Text>

            <Text type="body">
              À partir du{" "}
              <strong>
                {DateTimeUtils.formatDate(subscription.trial_end_date)}
              </strong>
              , vous serez facturé(e){" "}
              <strong>
                de {NumberUtils.currencyFormat(newTier?.flat_price || 0)}
                &nbsp;HT soit{" "}
                {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
                &nbsp;TTC
              </strong>{" "}
              chaque{" "}
              {TranslatorUtils.translate(newPlan?.period_unit || "month")}, à
              cette date.
            </Text>
          </>
        ),
      };
    default:
      return {
        content: <></>,
      };
  }
};

type Props = {
  currentSubscription: Subscription;
  newPlan: SubscriptionPlan;
  disableSubsRefreshOnSuccess?: boolean;
};
const UpsellConfirmationModal = ({
  currentSubscription,
  newPlan,
  disableSubsRefreshOnSuccess,
}: Props) => {
  const app = useApp();
  const isMobile = useIsMobile();
  const segment = useSegment();

  const newTier = newPlan && SubscriptionsService.getTierToSell(newPlan);

  const [extraData, setExtraData] = useState<
    ProratedAmountResponseData | undefined
  >();
  const [changeConfirmed, setChangeConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const _getProratedAmount = useCallback(async () => {
    if (
      currentSubscription?.id &&
      newPlan?.id &&
      currentSubscription.status === SubscriptionStatus.Active
    ) {
      const result = await SubscriptionsService.getProratedAmount(
        currentSubscription?.id,
        newPlan?.id
      );
      setExtraData(result);
    }
  }, [currentSubscription?.id, currentSubscription?.status, newPlan?.id]);

  useEffect(() => {
    _getProratedAmount();
  }, [_getProratedAmount]);

  useEffect(() => {
    segment.track("subscription update modale: displayed", {
      "legalentity id": app.currentLegalEntityId,
      "sub family": currentSubscription?.family.slug,
      "sub product": currentSubscription?.product.slug,
      "update type": "upsell",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    app.closeModal();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeConfirmed(event.target.checked);
  };

  const handleChangePlan = async () => {
    if (!currentSubscription || !newTier) return;
    setIsSubmitting(true);
    try {
      await SubscriptionsService.switchTier(currentSubscription.id, newTier.id);

      if (!disableSubsRefreshOnSuccess) {
        // Update subscriptions with latest info
        await app.refreshSubscriptions();
      }

      app.openModal({
        name: ModalName.SubsMgmntSwitchTierSuccess,
        openedBy: {
          context: "change-plan-upsell",
          from: "successful-request",
        },
      });
    } catch (error: any) {
      setError(error.message || "Échec du changement de plan.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const { title, content } = getContentByStatus(
    currentSubscription!,
    newPlan,
    newTier,
    extraData
  );

  return (
    <Modal isOpen={true} hideCloseButton onClose={closeModal}>
      <InnerContainer>
        <Column>
          <span style={{ textAlign: isMobile ? "left" : "center" }}>
            <Title type={isMobile ? "H1" : "H2"}>
              {title || "Changement d’abonnement"}
            </Title>
          </span>

          {content}
        </Column>

        <div className="checkbox_container">
          <Checkbox
            id="change-confirmation-upsell"
            onChange={handleCheckboxChange}
          />
          <label htmlFor="change-confirmation-upsell">
            Je confirme vouloir modifier mon abonnement{" "}
            <strong>
              {SubscriptionsService.getFamilyDisplayName(currentSubscription)}
            </strong>
            .
          </label>
        </div>

        {error && (
          <Text color="error.dark" fontWeightVariant="bold">
            {error}
          </Text>
        )}

        <ActionsContainer>
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!changeConfirmed || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleChangePlan}
          >
            Valider
          </Button>
        </ActionsContainer>
      </InnerContainer>
    </Modal>
  );
};

export default UpsellConfirmationModal;
