import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ModalInnerContainer = styled(Column)`
  align-items: center;
  text-align: center;
`;

/** The Search Params flag to trigger the new shareholder account created modal */
const SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED =
  "new_shareholder_account_created";
/** TODO: remove this once BE is updated
 * @deprecated use `SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED` instead
 */
const DEPRECATED_SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED =
  "capital_deposit_shareholder";

const NewShareholderAccountCreatedModal: React.FC = () => {
  const app = useApp();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.has(SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED) ||
      // TODO: remove this once BE is updated
      searchParams.has(DEPRECATED_SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED)
    ) {
      app.openModal({
        name: ModalName.LSCPNewShareholderAccountCreated,
        openedBy: {
          context: "",
          from: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    // Remove the search params
    searchParams.delete(SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED);
    // TODO: remove this once BE is updated
    searchParams.delete(
      DEPRECATED_SEARCH_PARAM_NEW_SHAREHOLDER_ACCOUNT_CREATED
    );
    setSearchParams(searchParams);

    // close the modal
    app.closeModal();
  };

  return (
    <Modal
      isOpen={
        app.openingModal?.name === ModalName.LSCPNewShareholderAccountCreated
      }
      onClose={closeModal}
    >
      <ModalInnerContainer>
        <Title type="H3">
          Félicitations,
          <br />
          votre compte est créé et sécurisé&nbsp;!
        </Title>

        <Text>La création de votre société avance bien.</Text>

        <Button onClick={closeModal}>J’ai compris&nbsp;!</Button>
      </ModalInnerContainer>
    </Modal>
  );
};

export default NewShareholderAccountCreatedModal;
