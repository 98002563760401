import { useLegalEntity } from "hooks";
import { useMemo } from "react";
import { matchPath } from "react-router-dom";
import { SubscriptionsService } from "services";
import { LSCPRoutePath, PathTemplate } from "services/router";
import { LegalEntity } from "types/legal-entities";
import {
  LSCPAccessibleStatuses,
  LSCPProductFeature,
  SubscriptionFamilySlug,
} from "types/subscriptions";

/** Map the Location Path vs a Product Feature */
const mapPathFeature: Record<LSCPRoutePath, LSCPProductFeature | undefined> = {
  [LSCPRoutePath.CapitalDeposit]: undefined,
  [LSCPRoutePath.GenerateInvoice]: LSCPProductFeature.GenerateInvoice,
  [LSCPRoutePath.MyExpenses]: LSCPProductFeature.MyExpenses,
  [LSCPRoutePath.MyLatestTransactions]: LSCPProductFeature.MyLatestTransactions,
  [LSCPRoutePath.ProAccount]: LSCPProductFeature.ProAccount,
};

export type LSCPSubscriptionHookReturn = ReturnType<typeof useLSCPSubscription>;

const useLSCPSubscription = (legalEntity?: LegalEntity) => {
  const currentLegalEntity = useLegalEntity(legalEntity);

  const lscpSubscription = useMemo(() => {
    return currentLegalEntity.findSubscription(
      SubscriptionFamilySlug.LSBusiness
    );
  }, [currentLegalEntity]);

  /** Find accessible LS Compte Pro subscription with statuses decided by product team */
  const extendFunctions = useMemo(() => {
    /** Build and return the SSO URL if it's qualified. Otherwise, return `null`. */
    const buildSSORedirectionURL = (lsBusinessPathname?: LSCPRoutePath) => {
      // Qualification: LE has Siren & LSCP Sub has a Redirection URL
      if (
        !!currentLegalEntity.compte_pro_active &&
        !!lscpSubscription?.redirection_url
      ) {
        // Build the Destination URL
        let destinationURL =
          // It's expected to be https://business.legalstart.fr/companies/{company_id}
          `${lscpSubscription.redirection_url}/${lsBusinessPathname || ""}`;

        // As of November 2024, we are using a dispatcher service for dealing with all redirection
        // This dispatcher is expecting to receive the destination URL after SSO login as a base64 json
        // containing the original destination URL and the testenv base URL to point to
        if (process.env.REACT_APP_ENVIRONMENT_NAME !== "production") {
          destinationURL = btoa(
            JSON.stringify({
              original_state: destinationURL,
              base_url: process.env.REACT_APP_CORE_API_URL,
            })
          );
        }

        // Build the SSO params with Destination URL
        const encodedDestinationURL = encodeURIComponent(destinationURL);
        // Build the complete SSO URL
        const ssoURL = `${process.env.REACT_APP_LS_COMPTE_PRO_SSO_URL}?RelayState=${encodedDestinationURL}`;

        // Return SSO URL
        return ssoURL;
      }

      // Not qualified
      return null;
    };

    const currentProduct = lscpSubscription?.product;

    /** check whether the current feature need a higher product level to access by its pathname */
    const needHigherProductLevel = (pathname: string) => {
      const matchedLSCPPath = matchPath(
        { path: `${PathTemplate.LegalEntityBase}/*`, end: false },
        pathname
      );

      // Extract the path to identify feature
      const featurePath = matchedLSCPPath?.params["*"];

      if (currentProduct && featurePath) {
        // Identify the feature based on the location path
        const feature = mapPathFeature[featurePath as LSCPRoutePath];
        const productFeatures = currentProduct.features;

        if (feature && !productFeatures.includes(feature)) {
          return true;
        }
      }

      return false;
    };

    return {
      buildSSORedirectionURL,
      needHigherProductLevel,
    };
  }, [lscpSubscription, currentLegalEntity.compte_pro_active]);

  if (!lscpSubscription) return undefined;

  /** Accessible statuses decided by product team */
  const isAccessible = LSCPAccessibleStatuses.includes(lscpSubscription.status);

  return {
    ...lscpSubscription,
    ...extendFunctions,
    isAccessible,
    familyDisplayName:
      SubscriptionsService.getFamilyDisplayName(lscpSubscription),
  };
};

export default useLSCPSubscription;
