import { AxiosResponse } from "axios";
import { ERROR_MESSAGES } from "./constants";

const DELAY_DEFAULT = 1000; // ms

let retryCount = 0;

type Arguments<T> = {
  request: () => Promise<AxiosResponse<T>>;
  successCondition: (response: AxiosResponse<T>) => boolean;
  failureCondition: (response: AxiosResponse<T>) => boolean;
  delay?: number;
  retries?: number;
};

const pollingRequest = async <T>(
  args: Arguments<T>
): Promise<AxiosResponse<T>> => {
  const {
    request,
    successCondition,
    failureCondition,
    delay = DELAY_DEFAULT,
    retries,
  } = args;
  return new Promise((resolve, reject) => {
    const sendRequest = async () => {
      try {
        const response = await request();

        if (!response) {
          throw new Error(ERROR_MESSAGES.DEFAULT, {
            cause: "[PollingRequest] Request failed",
          });
        }

        if (successCondition(response)) {
          return resolve(response);
        }

        if (failureCondition(response)) {
          return reject(response);
        }

        retryCount++;

        if (retries && retryCount >= retries) {
          // Reset counter
          retryCount = 0;

          // Reject with an error
          throw new Error(ERROR_MESSAGES.DEFAULT, {
            cause: "[PollingRequest] Max retries reached",
          });
        }

        // Plan a retry after X
        setTimeout(sendRequest, delay);
      } catch (error) {
        return reject(error);
      }
    };

    sendRequest();
  });
};

export default pollingRequest;
