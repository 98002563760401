import { Button, Text, Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { BackButton } from "pages/components";
import BenefitList from "pages/components/subscriptions/BenefitList";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionsService } from "services";
import BenefitIcon, {
  BenefitIconType,
} from "services/subscriptions/conf/utils/BenefitIcon";
import { computeBenefitLabel } from "services/subscriptions/conf/utils/product-benefits";
import styled, { css } from "styled-components";
import { SubscriptionProductFeatureItem } from "types/subscriptions";
import SubscriptionViewContext from "../../SubscriptionViewContext";
import ChangePlanContext from "../ChangePlanContext";

const ComparisonContainer = styled.div`
  max-width: 640px;

  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.neutral.darker};
    row-gap: ${theme.spacing.s}px;
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.s}px;
    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column-reverse;
    }
  `}
`;

const BenefitsComparisonPage = () => {
  const app = useApp();
  const navigate = useNavigate();
  const changePlanContext = useContext(ChangePlanContext.Context);
  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);

  const { newPlan } = changePlanContext.state;
  const { currentPlan } = subscriptionViewContext.state;

  const backToPlanSelectionPage = () => {
    navigate("..", { replace: true });
  };

  if (!currentPlan || !newPlan) {
    backToPlanSelectionPage();
  }

  const confirmDownsell = () => {
    app.openModal({
      name: ModalName.SubsMgmntDownsellConfirmation,
      openedBy: {
        context: "change-plan",
        from: "plan-item",
      },
    });
  };

  const benefitDiffs = useMemo(() => {
    const currentBenefits = SubscriptionsService.getProductBenefits(
      currentPlan?.product
    );
    const newBenefits = SubscriptionsService.getProductBenefits(
      newPlan?.product
    );

    // Create Maps for faster lookup
    const currentMap = new Map();
    const newMap = new Map();

    // Populate new benefits map
    currentBenefits?.forEach((b) => currentMap.set(b.description, b));
    newBenefits?.forEach((b) => newMap.set(b.description, b));

    const differences: SubscriptionProductFeatureItem[] = [];

    currentBenefits?.forEach((currentBenefit) => {
      const description = currentBenefit.description;
      const newBenefit = newMap.get(description);

      // C1: Benefit exists in current but not in new
      if (!newBenefit) {
        differences.push({ ...currentBenefit });
      }
      // C2: Benefits exists in both but with different count
      if (currentBenefit.count && currentBenefit.count !== newBenefit.count) {
        const countDiff = Math.abs(currentBenefit.count - newBenefit.count);
        differences.push({
          ...currentBenefit,
          count: countDiff,
        });
      }
    });

    return differences;
  }, [currentPlan, newPlan]);

  return (
    <>
      <BackButton replace />

      <div className="content-container">
        <div className="page_title">
          <Title type="H2">
            Êtes-vous vous sûr(e) de vouloir changer de formule&nbsp;?
          </Title>
        </div>

        <ComparisonContainer>
          <Text>
            En passant de la formule{" "}
            <strong>{currentPlan?.product.name}</strong> à{" "}
            <strong>{newPlan?.product.name}</strong>, vous ne pourrez plus
            utiliser ces fonctionnalités importantes&nbsp;:
          </Text>

          <BenefitList className="benefits_list align-self-center">
            {benefitDiffs?.map((benefit) => (
              <li key={benefit.description} className="benefit_item">
                <BenefitIcon type={BenefitIconType.Negative} />
                <Text type="small" color="neutral.dark">
                  {computeBenefitLabel(
                    benefit.label_downselling ||
                      benefit.label_loosing ||
                      benefit.description,
                    benefit.count
                  )}
                </Text>
              </li>
            ))}
          </BenefitList>
        </ComparisonContainer>

        <ActionsContainer>
          <Button variant="secondary" onClick={backToPlanSelectionPage}>
            Annuler
          </Button>
          <Button onClick={confirmDownsell}>Confirmer</Button>
        </ActionsContainer>
      </div>
    </>
  );
};

export default BenefitsComparisonPage;
