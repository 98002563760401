import { LegalEntity } from "./legal-entities";
import { SubscriptionPlan } from "./subscriptions";

export enum PartnerSlug {
  Pennylane = "pennylane",
}

export enum PartnerServiceSlug {
  Bank = "bank",
}

export interface RequestDataCreateLeadFromLegalEntity {
  plan: SubscriptionPlan["id"];
}

interface CoreAPIRequest<PathVars, ResponseData> {
  request: {
    pathVariables: PathVars;
  };
  response: {
    data: ResponseData;
  };
}

export enum PartnerFlowEventStatus {
  ErrorStandaloneUserCreation = "error_standalone_user_creation",
  ErrorStandaloneCompanyCreation = "error_standalone_company_creation",
  ErrorSubscriptionCreation = "error_subscription_creation",

  /** Final success status */
  SuccessSubscriptionCreation = "success_subscription_creation"
}

export type RequestGetLeadByLegalEntity = CoreAPIRequest<
  {
    legalEntityId: LegalEntity["id"];
    partnerSlug: PartnerSlug;
    partnerServiceSlug: PartnerServiceSlug;
  },
  {
    external_id: string;
    id: number;
    lead_id: number;
    plan_id: number;
    step?: string;
    status?: PartnerFlowEventStatus;
    detail?: string | null;
  }
>;
