import {
  AccountancyFlat,
  CalendarFlat,
  CompteProFlat,
  DashboardFlat,
  HeadphoneFlat,
  OtherServicesFlat,
} from "@yolaw/ui-kit-icons";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { styled, useTheme } from "styled-components";

import useAppRoutes from "hooks/useAppRoutes";
import useLSCPSubscription from "pages/ls-compte-pro/hooks/useLSCPSubscription";
import { SubscriptionsService } from "services";
import { BuiltRoutePath, LSCPRoutePath } from "services/router";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import {
  CapitalDepositStep,
  LegalStructure,
  LegalStructureType,
} from "types/legal-entities";
import { useLegalEntity, useSegment, useUser } from "../../hooks";
import MultiLevelNavigationItem from "./MultiLevelNavigationItem";
import NavigationItem from "./NavigationItem";

const Container = styled.nav``;

const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.s}px;
  list-style-type: none;
`;

const NavigationSubList = styled(NavigationList)`
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  overflow: hidden;
`;

const SubNavigationItem = styled(NavigationItem)`
  && > * {
    padding-left: calc(
      var(--nav-item-padding) + var(--nav-item-icon-width) +
        var(--nav-item-column-gap)
    );
    font-size: 14px;
  }
`;

const SOJNavItem = () => {
  const currentLegalEntity = useLegalEntity();

  /** @see https://app.clickup.com/t/2571097/TECH-43552 */
  const sojSubscription = currentLegalEntity.findSOJActiveSubscription();

  if (!sojSubscription) return null;

  const redirectToSOJ = () =>
    window.location.assign(
      `${process.env.REACT_APP_WEB_APP_DOMAIN}${
        sojSubscription.redirection_url || "/compte/service-conformite/"
      }`
    );

  return (
    <NavigationItem
      label="Obligations juridiques"
      icon={<CalendarFlat size="24" />}
      onClick={redirectToSOJ}
    />
  );
};

const ComptastartNavItem = () => {
  const segment = useSegment();
  const { navigateTo } = useAppRoutes();
  const currentLegalEntity = useLegalEntity();

  const activeComptastartSub = currentLegalEntity.findCSActiveSubscription();

  const handleComptastartAccess = () => {
    segment.track("comptastart: displayed", {
      "sub comptastart": activeComptastartSub
        ? activeComptastartSub.status
        : "none",
      cycle: activeComptastartSub?.plan.period_unit,
      "price (ht)": activeComptastartSub?.plan.price_et,
      type: activeComptastartSub?.plan.type_slug,
    });

    if (activeComptastartSub?.redirection_url) {
      window.open(activeComptastartSub.redirection_url);
    } else {
      navigateTo(BuiltRoutePath.ComptastartPage);
    }
  };

  return (
    <NavigationItem
      label={<span translate="no">Comptastart</span>}
      icon={<AccountancyFlat size="24" />}
      onClick={handleComptastartAccess}
    />
  );
};

const LSCompteProOrComptastartNavItem = () => {
  const segment = useSegment();
  const theme = useTheme();
  const { generateAppPath } = useAppRoutes();
  const currentLegalEntity = useLegalEntity(null, {
    needKDep: true,
    needOwner: true,
  });

  const lscpSubscription = useLSCPSubscription();

  const isLegalEntityOwner = currentLegalEntity.isLegalEntityOwner;
  const myKDep = currentLegalEntity.myKDep;

  // Find the active Comptastart subscription
  const activeComptastartSub = currentLegalEntity.findCSActiveSubscription();
  const isCSSubWithLEStandaloneForSubscription =
    !!activeComptastartSub &&
    !!currentLegalEntity.is_standalone_for_subscription;

  if (
    (isLegalEntityOwner && lscpSubscription) ||
    (!isLegalEntityOwner &&
      myKDep?.step === CapitalDepositStep.ACCOUNT_ACTIVATED) ||
    currentLegalEntity.isEligibleForLSCPStandalone
  ) {
    const computeLSCPPath = (paths: {
      lsBusinessPathname: LSCPRoutePath;
      userspacePathname: string;
    }) => {
      const { lsBusinessPathname, userspacePathname } = paths;

      const appPath = generateAppPath(userspacePathname);

      if (lscpSubscription?.needHigherProductLevel(appPath)) {
        return appPath;
      }

      const ssoURL =
        lscpSubscription?.buildSSORedirectionURL(lsBusinessPathname);

      return ssoURL || appPath;
    };

    const trackingEventProperties = {
      "legalentity id": currentLegalEntity.id,
      has_siren: !!currentLegalEntity.siren,
      "ls-cpro sub plan": lscpSubscription?.plan.period_unit,
      "ls-cpro sub product": lscpSubscription?.product.name,
      "ls-cpro sub family": lscpSubscription?.family.name,
    };

    const handleMyProAccountClick = () => {
      segment.track("ls compte pro account: clicked", trackingEventProperties);
    };

    const handleMyLatestTransactionsClick = () => {
      segment.track(
        "ls compte pro operations: clicked",
        trackingEventProperties
      );
    };

    const handleGenerateInvoiceClick = () => {
      segment.track(
        "ls compte pro customer invoices: clicked",
        trackingEventProperties
      );
    };

    const handleManageExpensesClick = () => {
      segment.track(
        "ls compte pro expense claim: clicked",
        trackingEventProperties
      );
    };

    return (
      <>
        <MultiLevelNavigationItem
          label={
            <span translate="no">
              {SubscriptionsService.getProductLineDisplayName(
                currentLegalEntity.eligibleLSCPProductLine
              )}
            </span>
          }
          icon={<CompteProFlat size="24" color={theme.colors.secondary.main} />}
          open
        >
          <NavigationSubList>
            <SubNavigationItem
              label="Mon compte professionnel"
              onClick={handleMyProAccountClick}
              path={computeLSCPPath({
                lsBusinessPathname: LSCPRoutePath.ProAccount,
                userspacePathname: BuiltRoutePath.LSCPProAccountPage,
              })}
            />
            <SubNavigationItem
              label="Mes dernières transactions"
              onClick={handleMyLatestTransactionsClick}
              path={computeLSCPPath({
                lsBusinessPathname: LSCPRoutePath.MyLatestTransactions,
                userspacePathname: BuiltRoutePath.LSCPMyLatestTransactionsPage,
              })}
            />
            <SubNavigationItem
              label="Générer une facture client"
              onClick={handleGenerateInvoiceClick}
              path={computeLSCPPath({
                lsBusinessPathname: LSCPRoutePath.GenerateInvoice,
                userspacePathname: BuiltRoutePath.LSCPGenerateInvoicePage,
              })}
            />
            <SubNavigationItem
              label="Gérer mes notes de frais"
              onClick={handleManageExpensesClick}
              path={computeLSCPPath({
                lsBusinessPathname: LSCPRoutePath.MyExpenses,
                userspacePathname: BuiltRoutePath.LSCPMyExpensesPage,
              })}
            />
          </NavigationSubList>
        </MultiLevelNavigationItem>
        {isCSSubWithLEStandaloneForSubscription && <ComptastartNavItem />}
      </>
    );
  }

  // No Active LS Compte Pro subscription found

  // TODO: remove LS Compta Basic related code as it won't be supported anymore (need product confirmation)
  const lsComptaBasicSub = currentLegalEntity.findLegacySubscription(
    LegacySubscriptionSlug.LSComptaBasic
  );

  // Just display the LS Compta if an active subscription is found
  if (lsComptaBasicSub)
    return (
      <NavigationItem
        label={<span translate="no">LS Compta</span>}
        icon={<AccountancyFlat size="24" />}
        path={generateAppPath(BuiltRoutePath.LSComptaBasicPage)}
      />
    );

  /** Otherwise, check to see whether we should display the Comptastart or not
   * @see https://app.clickup.com/t/2571097/TECH-43552
   * */

  const shouldDisplayComptastart =
    (isLegalEntityOwner &&
      currentLegalEntity.isOneOfLegalStructureTypes([
        LegalStructureType.Commercial,
        LegalStructureType.RealEstate,
        LegalStructureType.Association,
        LegalStructureType.MicroOrAutoEnterprise,
      ])) ||
    isCSSubWithLEStandaloneForSubscription;

  if (!shouldDisplayComptastart) return null;

  return <ComptastartNavItem />;
};

const ZenNavItem = () => {
  const { generateAppPath } = useAppRoutes();
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();

  const [zenAccessURL, setZenAccessURL] = useState<string | null>(null);

  const getAccessURL = useCallback(async () => {
    const url = await currentLegalEntity.getZenAccessURL();
    setZenAccessURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntity?.id, currentLegalEntity.zenSub]);

  useEffect(() => {
    getAccessURL();
  }, [getAccessURL]);

  if (!zenAccessURL) return null;

  return (
    <MultiLevelNavigationItem
      label={<span translate="no">Zen</span>}
      icon={<CalendarFlat size="24" />}
      open={!lscpSubscription}
    >
      <NavigationSubList>
        <SubNavigationItem
          label="Calendrier des obligations"
          path={generateAppPath(BuiltRoutePath.LegalsObligationsPage)}
        />
        <SubNavigationItem
          label="Optimisation de votre société"
          path={generateAppPath(BuiltRoutePath.AdministrativeDashboardPage)}
        />
        <SubNavigationItem
          label="Registre des décisions"
          path={generateAppPath(BuiltRoutePath.MandatoryRecordsPage)}
        />
        {/* ONLY display this sub menu item for SAS and SASU */}
        {(currentLegalEntity.isLegalStructure(LegalStructure.SAS) ||
          currentLegalEntity.isLegalStructure(LegalStructure.SASU)) && (
          <SubNavigationItem
            label="Registre des mouvements de titres"
            path={generateAppPath(BuiltRoutePath.TitlesRecordsPage)}
          />
        )}
      </NavigationSubList>
    </MultiLevelNavigationItem>
  );
};

const AJNavItem = () => {
  const user = useUser();
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const ajSub = user.findAJActiveSubscription();

  const handleAssistanceItemClick = useCallback(() => {
    segment.track("assistance: displayed", {
      "legalentity id": currentLegalEntity.id,
      "aj sub status": ajSub?.status,
      "aj sub slug": ajSub?.plan.type_slug,
    });

    window.location.assign(
      `${process.env.REACT_APP_WEB_APP_DOMAIN}/compte/besoin-aide/`
    );
  }, [ajSub, currentLegalEntity, segment]);

  return (
    <NavigationItem
      label="Assistance"
      icon={<HeadphoneFlat size="24" />}
      // Temporarily redirect to the old webapp userspace instead
      // @see https://app.clickup.com/t/2571097/TECH-51487
      // path={BuiltRoutePath.LegalAssistancePage}
      onClick={handleAssistanceItemClick}
    />
  );
};

const Navigation: FC = memo(() => {
  const theme = useTheme();

  return (
    <Container>
      <NavigationList>
        <NavigationItem
          label="Accueil"
          icon={<DashboardFlat size="24" color={theme.colors.secondary.main} />}
          path={BuiltRoutePath.HomePage}
        />

        <LSCompteProOrComptastartNavItem />

        <ZenNavItem />

        <SOJNavItem />

        <AJNavItem />

        <NavigationItem
          label="Autres services"
          icon={<OtherServicesFlat size="24" />}
          path="other-services"
        />
      </NavigationList>
    </Container>
  );
});

export default Navigation;
