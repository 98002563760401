import { Column, TextField } from "@yolaw/ui-kit-components";
import { useApp } from "hooks";
import { useEffect } from "react";
import {
  Controller,
  ControllerProps,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled, { css } from "styled-components";
import LSUser from "types/user";
import { PASSWORD_MIN_LENGTH } from "utils/constants";
import {
  containSpecialCharacterOrNumber,
  notContainUserInfo,
} from "../set-password/utils";
import CriteriaValidationRow from "./CriteriaValidationRow";

export const PasswordForm = styled(Column).attrs({
  as: "form",
})`
  align-self: center;

  ${({ theme }) => css`
    && {
      @container (max-width: ${theme.breakpoints.m}px) {
        row-gap: ${theme.spacing.s}px;
      }

      @container (min-width: ${theme.breakpoints.m + 1}px) {
        align-items: center;
        row-gap: ${theme.spacing.m}px;
      }
    }
  `}
`;

export const FieldSetContainer = styled(Column)`
  width: 100%;
`;

const CriteriaValidationContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `};
`;

export type NewPasswordFieldsetInputs = {
  password: string;
  confirmPassword: string;
};

type NewPasswordFieldsetProps<T> = {
  autoFocus?: boolean;
  labels: Record<keyof T, string>;
};

/** The fieldset to use when setting a new password.
 * @warning ⚠️ Wrap the form with the `FormProvider` component for `useFormContext` to work properly.
 * @warning ⚠️ Set `criteriaMode: "all"` in `useForms` hook.
 * */
const NewPasswordFieldset = <T extends NewPasswordFieldsetInputs>({
  autoFocus,
  labels,
}: NewPasswordFieldsetProps<T>) => {
  const { user } = useApp();

  const { control, formState, getFieldState, trigger } =
    useFormContext<NewPasswordFieldsetInputs>();
  const { errors } = formState;

  const passwordFieldState = getFieldState("password");

  const password = useWatch({
    control,
    name: "password",
  });

  const confirmPassword = useWatch({
    control,
    name: "confirmPassword",
  });

  const hasValidatedPasswordOnce = !!password && passwordFieldState.isTouched;

  useEffect(() => {
    if (confirmPassword) trigger("confirmPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, trigger]);

  const validationRules: Record<string, ControllerProps["rules"]> = {
    password: {
      required: true,
      // Only do these validations when the field has value
      validate: {
        minLength: (value: string) =>
          value ? value.length >= PASSWORD_MIN_LENGTH : true,
        specialCharOrNumber: (value: string) =>
          value ? containSpecialCharacterOrNumber(value) : true,
        containUserInfo: (value: string) =>
          value && !!user
            ? notContainUserInfo(value, user as LSUser.Info)
            : true,
      },
    },
    confirmPassword: {
      required: true,
      validate: {
        syncPasswordError: (value: string) => !!value && !errors.password,
        matchPassword: (value: string) =>
          !!value && !!password ? value === password : true,
      },
    },
  };

  return (
    <FieldSetContainer>
      <Controller
        control={control}
        name="password"
        rules={{ ...validationRules?.password }}
        render={({ field: { ref, ...restOfField } }) => (
          <TextField
            type="password"
            id="password"
            placeholder={`${PASSWORD_MIN_LENGTH} caractères minimum`}
            autoFocus={autoFocus}
            label={labels.password}
            showLabel
            hasError={!!errors.password}
            errorMessage={""}
            {...restOfField}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmPassword"
        rules={{ ...validationRules?.confirmPassword }}
        render={({ field: { ref, ...restOfField } }) => (
          <TextField
            type="password"
            id="confirmPassword"
            placeholder="Répétez votre mot de passe"
            label={labels.confirmPassword}
            showLabel
            hasError={!!errors.confirmPassword}
            errorMessage={""}
            {...restOfField}
          />
        )}
      />
      <CriteriaValidationContainer>
        <CriteriaValidationRow
          label={`Au moins ${PASSWORD_MIN_LENGTH} caractères`}
          hasError={!!errors.password?.types?.["minLength"]}
          hasValidated={hasValidatedPasswordOnce}
        />
        <CriteriaValidationRow
          label="Au moins un chiffre ou un caractère spécial # @ ! ?"
          hasError={!!errors.password?.types?.["specialCharOrNumber"]}
          hasValidated={hasValidatedPasswordOnce}
        />
        <CriteriaValidationRow
          label="Ne doit pas contenir votre nom ni votre adresse email"
          hasError={!!errors.password?.types?.["containUserInfo"]}
          hasValidated={hasValidatedPasswordOnce}
        />
        <CriteriaValidationRow
          onlyShowOnError
          label="Les champs ci-dessus sont requis"
          hasError={
            errors.password?.type === "required" ||
            errors.confirmPassword?.type === "required"
          }
        />
        <CriteriaValidationRow
          onlyShowOnError
          label="Les mots de passe ne sont pas identiques"
          hasError={!!errors.confirmPassword?.types?.["matchPassword"]}
        />
      </CriteriaValidationContainer>
    </FieldSetContainer>
  );
};

export default NewPasswordFieldset;
