import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { displayToast } from "@yolaw/ui-kit-components";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import { useApp, useLegalEntity, useSegment } from "hooks";
import usePaymentMethodSetup from "pages/payment-methods/components/SetupModal/usePaymentMethodSetup";
import { useContext, useEffect } from "react";
import { PartnershipService } from "services";
import { PaymentMethod } from "services/payment";
import { PartnerServiceSlug, PartnerSlug } from "types/partnerships";
import { CardProcessorType } from "types/payment-methods";
import { SubscriptionPlan } from "types/subscriptions";
import { ERROR_MESSAGES } from "utils/constants";
import PaymentModalContext from "./Context";
import PayWithApplePayButton from "./PayWithApplePayBtn";
import PayWithCardButton from "./PayWithCardBtn";
import PayWithGooglePayBtn from "./PayWithGooglePayBtn";

type Props = {
  plan: SubscriptionPlan;
  buttonText?: string;
};
const PayConfirmButton = ({ plan, buttonText }: Props) => {
  const app = useApp();
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();
  const paymentModalContext = useContext(PaymentModalContext.Context);
  const { isSubmitting, modalRef } = paymentModalContext.state;

  const paymentForm = usePaymentFormContext();
  const { isReadyToPay, paymentMethod } = paymentForm.state;

  const paymentMethodSetup = usePaymentMethodSetup({
    setupFor: CardProcessorType.Company,
    paymentModalRef: modalRef,
  });

  const { setupError: paymentMethodSetupError } = paymentMethodSetup;

  const shouldDisableButton = !isReadyToPay || isSubmitting;

  useEffect(() => {
    if (paymentMethodSetupError) {
      paymentForm.action.setPaymentError(paymentMethodSetupError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodSetupError]);

  const setupPaymentMethod = async (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent
  ) => {
    await paymentMethodSetup.initiateNewPaymentMethod(
      stripePaymentMethodId,
      event,
      { set_as_default: false }
    );
  };

  const handleSubscribingProcess = async (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent,
    skipPaymentMethodSetup?: boolean
  ) => {
    paymentForm.action.setPaymentError(null);

    try {
      paymentModalContext.dispatch({
        type: PaymentModalContext.Action.SetIsSubmitting,
        payload: true,
      });

      // Setup payment method
      if (!skipPaymentMethodSetup) {
        await setupPaymentMethod(stripePaymentMethodId, event);
      }

      // PartnershipLead creation
      await PartnershipService.createLeadFromLegalEntity(
        currentLegalEntity.id,
        {
          plan: plan.id,
        }
      );

      // PartnershipLead polling to update status
      await PartnershipService.getLeadByLegalEntity({
        legalEntityId: currentLegalEntity.id,
        partnerSlug: PartnerSlug.Pennylane,
        partnerServiceSlug: PartnerServiceSlug.Bank,
      });

      segment.track("paywall: completed", {
        "subscription family": plan.family.slug,
        "subscription product": plan.product.slug,
        "subscription plan": plan.slug,
      });

      // refresh subscriptions
      await app.refreshSubscriptions();

      // display toast
      displayToast({
        type: "success",
        content:
          "Votre abonnement a été pris en compte. La facturation commencera dès la création de votre compte.",
      });

      // close modal
      app.closeModal();
    } catch (error: any) {
      paymentForm.action.setPaymentError({
        source: "ERROR_SOURCES.SUBSCRIBING_PROCESS",
        message: error.message || ERROR_MESSAGES.DEFAULT,
        code: String(error.code),
      });
    } finally {
      paymentModalContext.dispatch({
        type: PaymentModalContext.Action.SetIsSubmitting,
        payload: false,
      });
    }
  };

  if (paymentMethod === PaymentMethod.Card) {
    return (
      <PayWithCardButton
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleSubscribingProcess}
        buttonText={buttonText}
      />
    );
  }

  if (paymentMethod === PaymentMethod.ApplePay) {
    return (
      <PayWithApplePayButton
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleSubscribingProcess}
      />
    );
  }

  if (paymentMethod === PaymentMethod.GooglePay) {
    return (
      <PayWithGooglePayBtn
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleSubscribingProcess}
      />
    );
  }

  return <></>;
};

export default PayConfirmButton;
