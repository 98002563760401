import { Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import { LSLogo } from "@yolaw/ui-kit-icons";
import { SvgIconProps } from "@yolaw/ui-kit-icons/types/types/SvgIconProps";
import styled, { css } from "styled-components";

const Container = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};

    .buttons_container {
      text-align: center;
      a {
        color: ${theme.colors.secondary.main};
      }
    }

    > .card-footer > * {
      width: 100%;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.xs}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.s}px;
    }
  `}
`;

const HeaderContainer = styled(Row)`
  && {
    align-items: center;
    justify-content: flex-start;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    gap: ${theme.spacing.s}px;

    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column;
      justify-content: center;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.lighter};
`;

type SubscriptionCardProps = {
  header: {
    icon: string;
    title: string;
    subTitle: string;
  };
  body: {
    content: JSX.Element;
    buttons: JSX.Element;
  };
  footer: JSX.Element;
};

const SubscriptionCard = ({
  header,
  body,
  footer,
}: SubscriptionCardProps) => {
  const Icon: (props: SvgIconProps) => JSX.Element =
    require("@yolaw/ui-kit-icons/lib/index")[header.icon] || LSLogo;

  return (
    <Container className="subscription_card">
      {/* Header */}
      <HeaderContainer>
        <Icon size="40" />
        <Column style={{ rowGap: "4px" }}>
          <Title type="H4">{header.title}</Title>
          <Text type="small">{header.subTitle}</Text>
        </Column>
      </HeaderContainer>

      <Divider />

      {/* Body */}
      <ContentContainer>
        {/* Content */}
        {body.content}

        {/* Buttons */}
        <Column className="buttons_container">{body.buttons}</Column>
      </ContentContainer>

      <Divider />

      {/* Footer */}
      <div className="card-footer">{footer}</div>
    </Container>
  );
};

export default SubscriptionCard;
