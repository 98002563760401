import {
  PaymentMethod as StripePaymentMethod,
  SetupIntent as StripeSetupIntent,
} from "@stripe/stripe-js";

export enum CardProcessorType {
  Company = "Company",
  User = "User",
}

export interface PaymentMethodObject {
  can_be_detached: boolean;
  card_processor_type: CardProcessorType;
  payment_method: StripePaymentMethod | null;
}

export type SetupIntentRequestOptions = {
  set_as_default?: boolean;
}

interface SetupIntentResponseBase {
  setup_intent_id: string;
  setup_intent_status: StripeSetupIntent.Status;
}
export interface SetupIntentResponseSucceed
  extends SetupIntentResponseBase,
    PaymentMethodObject {
  setup_intent_status: "succeeded";
  client_secret: never;
}

export interface SetupIntentResponseRequiresAction
  extends SetupIntentResponseBase {
  setup_intent_status: "requires_action";
  client_secret: string;
}
