import { Button, Hint, HintType, Text, Title } from "@yolaw/ui-kit-components";
import Timeline from "components/timeline";
import { TTimelineElementStatus } from "components/timeline/timeline.types";
import { FormalitiesContext } from "contexts";
import { useIsMobile, useLegalEntity } from "hooks";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionsService } from "services";
import styled from "styled-components";
import { FormalityStatus } from "types/formalities";
import { LegalEntityVerificationStatus } from "types/legal-entities";
import { SubscriptionStatus } from "types/subscriptions";
import useLSCPSubscription from "../hooks/useLSCPSubscription";

const StyledHint = styled(Hint)`
  max-width: 568px;
`;

enum TimelineStep {
  CompanyCreation,
  AccountCreation,
  AddDocuments,
  AccessAccount,
}

type TimelineSectionConfig = {
  title: string;
  description: string;
  buttonText: string;
  getButtonDisabled: (currentStep: TimelineStep) => boolean;
  getButtonAction: () => void;
};

const getStepStatus = (step: TimelineStep, currentStep: TimelineStep) => {
  if (step === currentStep) return TTimelineElementStatus.Active;
  if (step < currentStep) return TTimelineElementStatus.Done;
  return TTimelineElementStatus.Locked;
};

const TimelineButton = ({
  config,
  currentStep,
  step,
}: {
  config: TimelineSectionConfig;
  currentStep: TimelineStep;
  step: TimelineStep;
}) => {
  const status = getStepStatus(step, currentStep);
  if (status === TTimelineElementStatus.Done) return null;

  return (
    <Button
      size="small"
      disabled={config.getButtonDisabled(currentStep)}
      onClick={config.getButtonAction}
    >
      {config.buttonText}
    </Button>
  );
};

const AccountActivationTimelinePage = () => {
  const isMobile = useIsMobile();
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();
  const navigate = useNavigate();
  const {
    state: { formalities },
  } = useContext(FormalitiesContext.Context);

  const familyDisplayName = lscpSubscription
    ? SubscriptionsService.getFamilyDisplayName(lscpSubscription)
    : "Compte Pro Legalstart";

  /* Get the redirection URL for LSCP subscription */
  const redirectionURL = useMemo(
    () =>
      lscpSubscription?.buildSSORedirectionURL() ||
      lscpSubscription?.redirection_url,
    [lscpSubscription]
  );

  /* Find related formality */
  const formality = useMemo(
    () =>
      formalities?.find(
        (f) =>
          f.legal_entity_id === currentLegalEntity.id &&
          f.status === FormalityStatus.InProgress
      ),
    [formalities, currentLegalEntity.id]
  );

  const currentStep = useMemo(() => {
    // Hasn't siren yet
    if (!currentLegalEntity.siren) return TimelineStep.CompanyCreation;

    // Has siren, waiting for account creation (verification process has not started yet)
    if (!currentLegalEntity.verification_status)
      return TimelineStep.AccountCreation;
    
    // Has siren, verification process has started
    switch (currentLegalEntity.verification_status) {
      case LegalEntityVerificationStatus.WaitingForInformation:
        return TimelineStep.AddDocuments;
      default:
        // Default is the closest step prior to the verification process
        return TimelineStep.AccountCreation;
    }
  }, [currentLegalEntity.siren, currentLegalEntity.verification_status]);

  /* Timeline section configurations */
  const timelineConfigs: Record<TimelineStep, TimelineSectionConfig> = {
    [TimelineStep.CompanyCreation]: {
      title: "Étape 1 : Création de la société",
      description:
        "Terminez la création de votre société pour obtenir votre SIREN et débloquer la prochaine étape.",
      buttonText: "Terminer l'immatriculation",
      getButtonDisabled: (currentStep) =>
        getStepStatus(TimelineStep.CompanyCreation, currentStep) ===
        TTimelineElementStatus.Locked,
      getButtonAction: () => {
        if (formality) {
          window.open(formality.detail_url, "_blank");
        } else {
          navigate("/");
        }
      },
    },
    [TimelineStep.AccountCreation]: {
      title: `Étape 2 : Création du compte ${familyDisplayName}`,
      description:
        "Renseignez les informations de votre entreprise pour lancer la création de votre compte.",
      buttonText: "Compléter mes informations",
      getButtonDisabled: (currentStep) =>
        getStepStatus(TimelineStep.AccountCreation, currentStep) ===
          TTimelineElementStatus.Locked || !redirectionURL,
      getButtonAction: () => {
        if (redirectionURL) {
          window.open(redirectionURL, "_blank");
        }
      },
    },
    [TimelineStep.AddDocuments]: {
      title: `Étape 3 : Justificatifs complémentaires requis`,
      description: `Ajoutez vos justificatifs supplémentaires pour finaliser votre compte ${familyDisplayName}`,
      buttonText: "Ajouter mes justificatifs",
      getButtonDisabled: (currentStep) =>
        getStepStatus(TimelineStep.AddDocuments, currentStep) ===
          TTimelineElementStatus.Locked || !redirectionURL,
      getButtonAction: () => {
        if (redirectionURL) {
          window.open(redirectionURL, "_blank");
        }
      },
    },
    [TimelineStep.AccessAccount]: {
      title: `Étape 4 : Accès à ${familyDisplayName}`,
      description: `Profitez de votre abonnement ${familyDisplayName}.`,
      buttonText: `Accéder à ${familyDisplayName}`,
      getButtonDisabled: () => true,
      getButtonAction: () => {},
    },
  };

  return (
    <PageContainer>
      <PageContentBody>
        {lscpSubscription?.status === SubscriptionStatus.Canceled ? (
          <>
            <Title type={isMobile ? "H1" : "H2"}>
              Abonnement à {familyDisplayName} annulé
            </Title>
            <StyledHint type={HintType.Information}>
              <Text type="body" fontWeightVariant="bold">
                Malheureusement, vous n'êtes pas éligible à {familyDisplayName}.
              </Text>
              <Text type="body" fontWeightVariant="bold">
                Aucun prélèvement ne sera effectué.
              </Text>
            </StyledHint>
          </>
        ) : (
          <>
            <Title type={isMobile ? "H1" : "H2"}>
              {currentLegalEntity.siren
                ? `${familyDisplayName} est bientôt prêt`
                : "Ouverture du compte en cours"}
            </Title>
            <Timeline
              sections={Object.entries(timelineConfigs).map(
                ([step, config]) => ({
                  status: getStepStatus(
                    Number(step) as TimelineStep,
                    currentStep
                  ),
                  title: config.title,
                  description: config.description,
                  button: (
                    <TimelineButton
                      config={config}
                      currentStep={currentStep}
                      step={Number(step) as TimelineStep}
                    />
                  ),
                })
              )}
            />
          </>
        )}
      </PageContentBody>
    </PageContainer>
  );
};

export default AccountActivationTimelinePage;
