import { Column, Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useLegalEntity } from "hooks";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import SubscriptionPlanList from "pages/components/subscriptions/SubscriptionPlanList";
import { ChangePlanType } from "pages/subscriptions-management/subscription-view/change-plan/ChangePlanContext";
import SwitchTierSuccessModal from "pages/subscriptions-management/subscription-view/change-plan/components/SwitchTierSuccessModal";
import UpsellConfirmationModal from "pages/subscriptions-management/subscription-view/change-plan/upsell/UpsellConfirmationModal";
import { useEffect, useState } from "react";
import { SubscriptionsService } from "services";
import styled, { css } from "styled-components";
import { SubscriptionFamilySlug, SubscriptionPlan } from "types/subscriptions";
import useLSCPSubscription from "../hooks/useLSCPSubscription";

const StyledPageContainer = styled(PageContainer)`
  max-width: 1080px;
`;

const PageHeader = styled<React.ElementType>(Column).attrs({ as: "header" })`
  ${({ theme }) => css`
    &.row-gap-m {
      row-gap: ${theme.spacing.m}px;
    }
  `}
`;

const LSCPStandaloneUpsellPage = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const currentLegalEntity = useLegalEntity();
  const lscpSubscription = useLSCPSubscription();
  const [newPlan, setNewPlan] = useState<SubscriptionPlan | undefined>();

  const [monthlyPlansToSell, setMonthlyPlansToSell] = useState<
    SubscriptionPlan[] | null
  >(null);

  const currentTier = lscpSubscription?.tier;
  const isAllowedToSubscribe = !!currentLegalEntity.siren;
  const upsellPlan = monthlyPlansToSell?.find(
    (p) => p.product.slug !== lscpSubscription?.product.slug
  );

  const _getData = async () => {
    try {
      const plans = await SubscriptionsService.getSubscriptionPlansForLE(
        SubscriptionFamilySlug.LSBusiness,
        currentLegalEntity.id
      );

      if (plans) {
        setMonthlyPlansToSell(
          SubscriptionsService.filterMonthlyPlansToSell(plans).filter((plan) =>
            isMobile
              ? SubscriptionsService.getTierToSell(plan).id !== currentTier?.id
              : plan
          ) // Filter out the current plan on mobile
        );
      } else {
        throw new Error("Failed to get data");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    _getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlanSelect = (plan: SubscriptionPlan) => {
    setNewPlan(plan);
    if (lscpSubscription) {
      // Existing subscriber wants to upsell/change plan
      app.openModal({
        name: ModalName.SubsMgmntUpsellConfirmation,
        openedBy: {
          context: "change-plan",
          from: "plan-item",
        },
      });
    }
  };

  const closeModalAndRedirect = async () => {
    const redirectionURL =
      lscpSubscription?.buildSSORedirectionURL() ||
      lscpSubscription?.redirection_url;

    if (redirectionURL) {
      window.open(redirectionURL, "_blank");
    }

    app.refreshSubscriptions();
    app.closeModal();
  };

  return (
    <>
      <StyledPageContainer>
        <PageContentBody>
          <PageHeader className={isAllowedToSubscribe ? "" : "row-gap-m"}>
            <Title type={isMobile ? "H1" : "H2"}>
              Gérer mes notes de frais avec la formule{" "}
              {upsellPlan?.product.name || "Essentiel"}
            </Title>
          </PageHeader>

          <SubscriptionPlanList
            currentTier={currentTier}
            onPlanSelect={handlePlanSelect}
            plans={monthlyPlansToSell}
            planItemConfigs={{
              disabled: !isAllowedToSubscribe,
            }}
          />
        </PageContentBody>
      </StyledPageContainer>

      {app.openingModal?.name === ModalName.SubsMgmntUpsellConfirmation &&
        lscpSubscription &&
        newPlan && (
          <UpsellConfirmationModal
            currentSubscription={lscpSubscription}
            newPlan={newPlan}
            disableSubsRefreshOnSuccess
          />
        )}
      {app.openingModal?.name === ModalName.SubsMgmntSwitchTierSuccess &&
        lscpSubscription &&
        newPlan && (
          <SwitchTierSuccessModal
            currentSubscription={lscpSubscription}
            newProduct={newPlan.product}
            downOrUp={ChangePlanType.Up}
            onConfirm={closeModalAndRedirect}
          />
        )}
    </>
  );
};

export default LSCPStandaloneUpsellPage;
