import { commonApiErrorHandler } from "pages/zen/services/apis/handlers/errors";
import ApiService from "services/api";
import { LegalEntity } from "types/legal-entities";
import {
  LSCPProductLine,
  Subscription,
  SubscriptionFamilySlug,
  SubscriptionPlan,
  SubscriptionProduct,
  SubscriptionStatus,
  SubscriptionTier,
} from "types/subscriptions";

export type SubscriptionFilters = {
  statuses?: SubscriptionStatus[];
};

/** Find subscription by family slug.
 * Can filter by statuses.
 */
export const findSubscription = (
  subscriptions: Subscription[],
  subFamilySlug: SubscriptionFamilySlug,
  filters?: SubscriptionFilters
) => {
  const { statuses } = filters || {};
  return subscriptions.find(
    (sub) =>
      sub.family.slug === subFamilySlug &&
      (statuses?.includes(sub.status) ?? true)
  );
};

export const getTierToSell = (plan: SubscriptionPlan) => {
  return plan.tiers[0];
};

export const filterMonthlyPlansToSell = (plans: SubscriptionPlan[]) =>
  plans?.filter((p) => p.period_unit === "month");

export const getFamilyDisplayName = (
  subOrPlan: Subscription | SubscriptionPlan
) =>
  subOrPlan.product.display?.main_display?.display_name ||
  subOrPlan.family.name;

export const getProductLineDisplayName = (
  productLine: LSCPProductLine | null
) => {
  switch (productLine) {
    case LSCPProductLine.LegalstartOne:
      return "Legalstart One";
    case LSCPProductLine.CompteProLegalstart:
    default:
      return "Compte Pro Legalstart";
  }
};

export const getProductBenefits = (product: SubscriptionProduct | undefined) =>
  product?.display?.feature_display
    ?.flatMap((g) => g.list_description)
    ?.filter((b) => b.contained_in_product);

export const getSubscriptions = async () => {
  try {
    const response = await ApiService.subscriptions.getSubscriptions();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};

export const getSubscriptionPlansForLE = async (
  subFamilySlug: SubscriptionFamilySlug,
  legalEntityId: LegalEntity["id"]
) => {
  try {
    const response = await ApiService.subscriptions.getSubscriptionPlansForLE(
      subFamilySlug,
      legalEntityId
    );
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};

export const switchTier = async (
  subscriptionId: Subscription["id"],
  tierId: SubscriptionTier["id"]
) => {
  try {
    const response = await ApiService.subscriptions.switchTier(
      subscriptionId,
      tierId
    );
    if (response?.status === 200) {
      return response;
    }

    throw new Error(response?.data?.message);
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

export const getProratedAmount = async (
  subscriptionId: Subscription["id"],
  newPlanId: SubscriptionPlan["id"]
) => {
  try {
    const response = await ApiService.subscriptions.getProratedAmount(
      subscriptionId,
      newPlanId
    );
    if (response?.status === 200 && response.data) {
      return response.data;
    }

    throw new Error("Échec du calcul du montant au prorata !");
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

export const terminateSubscription = async (
  subscriptionId: Subscription["id"]
) => {
  try {
    const response = await ApiService.subscriptions.terminateSubscription(
      subscriptionId
    );
    if (response?.status === 200) {
      return response.data;
    }

    throw new Error(response?.data?.message || "Échec de la résiliation !");
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};
