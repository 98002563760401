import * as Sentry from "@sentry/react";
import { ProjectContextState as ZenProjectContextState } from "pages/zen/context";
import React from "react";
import { LinkProps } from "react-router-dom";
import { LSPaymentMethod } from "types";
import { GetInvoicesResponseData } from "types/invoices";
import LegacySubscription from "types/legacy-subscriptions";
import { LegalEntity } from "types/legal-entities";
import { Subscription } from "types/subscriptions";
import LSUser from "types/user";
import { ArrayUtils } from "utils/array";

const defaultCountryOptions: CountryReferenceType[] = [
  {
    countryIso2Code: "FR",
    flagEmoji: "🇫🇷",
    intlDialCode: "+33",
    nameFr: "France",
  },
  {
    countryIso2Code: "DE",
    flagEmoji: "🇩🇪",
    intlDialCode: "+49",
    nameFr: "Allemagne",
  },
  {
    countryIso2Code: "IT",
    flagEmoji: "🇮🇹",
    intlDialCode: "+39",
    nameFr: "Italie",
  },
  {
    countryIso2Code: "DZ",
    flagEmoji: "🇩🇿",
    intlDialCode: "+213",
    nameFr: "Algérie",
  },
  {
    countryIso2Code: "PT",
    flagEmoji: "🇵🇹",
    intlDialCode: "+351",
    nameFr: "Portugal",
  },
];

export enum ModalName {
  AJSubscriptionPayment = "AJ_SUBSCRIPTION_PAYMENT_MODAL",
  CSSubscriptionPayment = "CS_SUBSCRIPTION_PAYMENT_MODAL",
  ERecordsWaitingForSiren = "E_RECORDS_WAITING_FOR_SIREN",
  FunnelWireTransferChangePaymentMethod = "FUNNEL_WIRE_TRANSFER_CHANGE_PAYMENT_METHOD",
  LSCPNewShareholderAccountCreated = "LSCP_NEW_SHAREHOLDER_ACCOUNT_CREATED",
  LSCPStandalonePayment = "LSCP_STANDALONE_PAYMENT_MODAL",
  PaymentMethodSetup = "PAYMENT_METHOD_SETUP",
  PaymentMethodDetach = "PAYMENT_METHOD_DETACH",
  RecordsOrderTitleRecordsSuccess = "RECORDS_ODER_TITLE_RECORDS_SUCCESS",
  ZenSubscriptionPayment = "ZEN_SUBSCRIPTION_PAYMENT_MODAL",
  ZenWaitingForSiren = "ZEN_WAITING_FOR_SIREN",
  // Subscriptions management modals
  SubsMgmntUpsellConfirmation = "SUBS_MGMNT_UPSELL_CONFIRMATION",
  SubsMgmntDownsellConfirmation = "SUBS_MGMNT_DOWNSELL_CONFIRMATION",
  SubsMgmntSwitchTierSuccess = "SUBS_MGMNT_SWITCH_TIER_SUCCESS",
}

export type OpeningModal = {
  name: ModalName;
  extraData?: any;
  /** Mostly used for tracking events to identify the source of opener  */
  openedBy?: {
    /** What is the app/feature opens the modal? */
    context: string;
    /** Where is the element triggered modal opening placed? */
    from: string;
  };
};

type AppContextState = {
  admin: LSUser.Info | null;
  configs: {
    countryReferences: CountryReferenceType[];
  };
  currentLegalEntityId: LegalEntity["id"] | null;
  invoices: GetInvoicesResponseData;
  legacySubscriptions: LegacySubscription.SubscriptionItem[];
  legalEntities: LegalEntity[] | null;
  webSubscriptions: WebSubscription[];

  /** App navigation related states */
  navigation: {
    /** Store the "to" value for the back button in the app header */
    headerBackTo?: LinkProps["to"];
  };
  openingModal: OpeningModal | null;
  paymentMethods: LSPaymentMethod.PaymentMethodObject[] | null;
  subscriptions: Subscription[] | null;
  user: LSUser.Info | null;

  _cache: {
    zenContextState: Partial<ZenProjectContextState> | null;
  };
};

const initialState: AppContextState = {
  admin: null,
  configs: {
    countryReferences: defaultCountryOptions,
  },
  currentLegalEntityId: null,
  invoices: {
    web_invoices: [],
    billing_documents: {},
  },
  openingModal: null,
  legacySubscriptions: [],
  legalEntities: null,
  webSubscriptions: [],

  navigation: {},
  paymentMethods: null,
  subscriptions: null,
  user: null,

  _cache: {
    zenContextState: null,
  },
};

export enum ActionType {
  CloseModal,
  OpenModal,
  SetAdminInfo,
  SetCurrentLegalEntityId,
  SetDefaultLEandSubs,
  SetInvoices,
  SetLegacySubscriptions,
  SetWebSubscriptions,
  SetNavHeaderBackTo,
  SetPaymentMethods,
  SetSubscriptions,
  SetUserInfo,
  UpdateCacheStore,
  UpdateCountryReferences,
  UpdateLegalEntity,
}

export type AppDispatchAction =
  | {
      type: ActionType.CloseModal;
    }
  | {
      type: ActionType.OpenModal;
      payload: OpeningModal;
    }
  | {
      type: ActionType.SetAdminInfo;
      payload: AppContextState["admin"];
    }
  | {
      type: ActionType.SetCurrentLegalEntityId;
      payload: LegalEntity["id"];
    }
  | {
      type: ActionType.SetDefaultLEandSubs;
      payload: Pick<
        AppContextState,
        "currentLegalEntityId" | "legalEntities" | "subscriptions"
      >;
    }
  | {
      type: ActionType.SetInvoices;
      payload: AppContextState["invoices"];
    }
  | {
      type: ActionType.SetLegacySubscriptions;
      payload: LegacySubscription.SubscriptionItem[];
    }
  | {
      type: ActionType.SetWebSubscriptions;
      payload: WebSubscription[];
    }
  | {
      type: ActionType.SetNavHeaderBackTo;
      payload: AppContextState["navigation"]["headerBackTo"];
    }
  | {
      type: ActionType.SetPaymentMethods;
      payload: AppContextState["paymentMethods"];
    }
  | {
      type: ActionType.SetSubscriptions;
      payload: Subscription[];
    }
  | {
      type: ActionType.SetUserInfo;
      payload: AppContextState["user"];
    }
  | {
      type: ActionType.UpdateCacheStore;
      payload: Partial<AppContextState["_cache"]>;
    }
  | {
      type: ActionType.UpdateCountryReferences;
      payload: CountryReferenceType[];
    }
  | {
      type: ActionType.UpdateLegalEntity;
      payload: LegalEntity;
    };

const reducer = (
  state: AppContextState,
  action: AppDispatchAction
): AppContextState => {
  switch (action.type) {
    case ActionType.CloseModal:
      if (state.openingModal) {
        const modalElement = document.getElementById(state.openingModal.name);
        modalElement?.classList.remove("opened");
      }
      return {
        ...state,
        openingModal: null,
      };
    case ActionType.OpenModal:
      const modalElement = document.getElementById(action.payload.name);
      modalElement?.classList.add("opened");
      return {
        ...state,
        openingModal: action.payload,
      };
    case ActionType.SetAdminInfo:
      Sentry.setContext("admin", action.payload);
      if (!action.payload?.is_staff) {
        return {
          ...state,
          admin: null,
        };
      }
      return {
        ...state,
        admin: action.payload,
      };
    case ActionType.SetCurrentLegalEntityId:
      Sentry.setContext(
        "currentLegalEntity",
        state.legalEntities?.find((le) => le.id === action.payload) || null
      );
      return {
        ...state,
        currentLegalEntityId: action.payload,
      };
    case ActionType.SetDefaultLEandSubs:
      const { currentLegalEntityId, legalEntities, subscriptions } =
        action.payload;
      return {
        ...state,
        currentLegalEntityId,
        legalEntities,
        subscriptions,
      };
    case ActionType.SetInvoices:
      return {
        ...state,
        invoices: action.payload,
      };
    case ActionType.SetLegacySubscriptions:
      return {
        ...state,
        legacySubscriptions: action.payload,
      };
    case ActionType.SetWebSubscriptions:
      return {
        ...state,
        webSubscriptions: action.payload,
      };
    case ActionType.SetNavHeaderBackTo:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          headerBackTo: action.payload,
        },
      };
    case ActionType.SetPaymentMethods:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case ActionType.SetSubscriptions:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ActionType.SetUserInfo:
      const { id, email } = action.payload || {};
      Sentry.setUser(action.payload ? { id, email } : null);
      return {
        ...state,
        user: action.payload,
      };
    case ActionType.UpdateCacheStore:
      return {
        ...state,
        _cache: {
          ...state._cache,
          ...action.payload,
        },
      };
    case ActionType.UpdateCountryReferences:
      return {
        ...state,
        configs: {
          ...state.configs,
          countryReferences: action.payload,
        },
      };
    case ActionType.UpdateLegalEntity:
      return {
        ...state,
        legalEntities: ArrayUtils.updateElementById(
          state.legalEntities!,
          action.payload.id,
          action.payload
        ),
      };
    default:
      throw new Error("[AppContext] unknown action type");
  }
};

type AuthContextType = {
  state: AppContextState;
  dispatch: React.Dispatch<AppDispatchAction>;
};

const Context = React.createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "AppContext";

const AppContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default AppContext;
